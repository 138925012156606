import React from 'react';
import FormComponent from '../components/FormComponent';
import { Widget } from '../components/Widget';
import GetData from '../utils/api';



const FormNormal = ({ data: { forms } }) => {
  const { data } = GetData((process.env.NODE_ENV !== 'development' && forms[0].freeform?.id) && `${process.env.REACT_APP_CRAFT_URL}/freeform/form/properties/${forms[0].freeform.id}`);
  return (
    <Widget className="formnormal">
      <div className="inner">
            <div className='left'>
              <div className="text">
                <h3>{forms[0].maintitle}</h3>
                <p>{forms[0].description}</p>
              </div>
            </div>
            <div className='right'>
              <FormComponent freeform={forms[0].freeform} complete={forms[0].complete} formProperties={data}/>
            </div>
        
      </div>
    </Widget>
  )
};

export default FormNormal;