import React, { useState } from 'react';
import FormComponent from '../components/FormComponent';
import Checkbox from '../components/Checkbox';
import { Widget } from '../components/Widget';
import GetData from '../utils/api';


const Form = ({ data: { forms } }) => {
  const [selectedForm, setSelectedForm] = useState();
  const { data } = GetData((process.env.NODE_ENV !== 'development' && (forms.length === 1 ? forms[0].freeform?.id : selectedForm?.freeform?.id)) && `${process.env.REACT_APP_CRAFT_URL}/freeform/form/properties/${forms.length === 1 ? forms[0].freeform?.id : selectedForm?.freeform?.id}`);
  return (
    <Widget className={`form ${selectedForm ? 'form-' + selectedForm.category.value : ""}`}>
      <div className="inner">
        {forms.length === 1 ?
          <>
            <div className='left'>
              <div className="text">
                <h3>{forms[0].maintitle}</h3>
                <p>{forms[0].description}</p>
              </div>
            </div>
            <div className='right'>
              <FormComponent freeform={forms[0].freeform} complete={forms[0].complete} formProperties={data}/>
            </div>
          </> :
          <>
            {!selectedForm ?
  
              forms.filter(form => "noform" === form.type).map((form, i) => {
                return (
                  <div key={`${form.type}${i}`} className='left'>
                    <h3>{form.maintitle}</h3>
                    <p>{form.description}</p>
                  </div>
                )
              }) : <div className='left'>
                <h3>{selectedForm?.maintitle}</h3>
                <p>{selectedForm?.description}</p>
              </div>
  
            }
            <div className='right'>
              {!selectedForm &&
                <div className='forms-options'>
                  {forms.filter(form => form.type === "formGlobal").map(form => {
                    return (
                      <Checkbox key={`${form.maintitle}`} attributes={
                        {
                          defaultValue: form.maintitle,
                          onChange: (event) => {
                            setSelectedForm(forms.filter(form => {
                              if (form.type === "formGlobal") {
                                return event.target.value === form.maintitle;
                              }
                              return false;
                            })[0]);
                          }
                        }
                      }
                        label={form.maintitle}
                        size={30} />
                    )
  
                  })}
                </div>
              }
              {selectedForm && <FormComponent freeform={selectedForm.freeform} complete={selectedForm.complete} formProperties={data}/>}
            </div>
          </>
        }
      </div>
    </Widget>
  )
};

export default Form;