import useSWR from 'swr'

const fetcher = (...args) => fetch(...args).then(res => res.json())

const GetData =  (url) => {
    const { data, error, isLoading,mutate } = useSWR(url, fetcher)
   
    return {
      data,
      loading:isLoading,
      error,
      mutate
    }
  }

export default GetData;