import React from 'react';
import { Link } from 'react-router-dom';
import { sanitizedData } from '../utils/sanitize';
import { Widget } from '../components/Widget';
import { LinkReplace } from '../components/LinkReplace';


const SplitText = ({ data }) => {
  return (
    <Widget className={`splittext  ${data.nobg === "true" ? 'nobg' : ''}`}>
      {data && data.splitText.map((item, i) => (
      <div className="inner"key={i}>
         <div className="textleft">
           <h3>{item.mainTitle}</h3>
           <div className="textdesc" dangerouslySetInnerHTML={sanitizedData(item.textLeft)}></div>
         </div>
         
         <div className="textexpand">
          <div className="txt" dangerouslySetInnerHTML={sanitizedData(item.textRight)}></div>
          { item.buttonDestinationUri ? <LinkReplace to={item.buttonDestinationUri} className="button full hover">{item.buttonTitle}</LinkReplace> : null }
         </div>
      </div>
      ))}
    </Widget>
  )
};

export default SplitText;