import React, { useState,useRef,useContext,useMemo,useEffect } from 'react';
import { buildQuery, runQuery } from '../utils/graphql';
import Input from './Input';
import Checkbox from './Checkbox';
import TextArea from './TextArea';
import { sanitizedData } from '../utils/sanitize';
import { Select } from './Select';
import { useHistory } from "react-router-dom";
import { SiteContext } from '../widgets/SiteContext';
import { getRules, showHideConditional } from '../utils/freeform';
import { CheckboxGroup } from './CheckboxGroup';
import { RadioGroup } from './RadioGroup';
import FileInput from './FileInput';
import { convertBase64 } from '../utils/base65';
import ReCAPTCHA from 'react-google-recaptcha';


const FormComponent = ({ freeform,complete,formProperties }) => {
    const layoutJson = JSON.parse(freeform.layoutJson);

    const rules = useMemo(() => {
        return getRules(layoutJson);
      }, [freeform]);

    const recaptchaRef = useRef();
    const navigate = useHistory();
    const {selectedSite } = useContext(SiteContext);
    const [formData, setFormData] = useState({});
    const [fieldTypes, setFieldTypes] = useState({});
    const [formPage, setFormPage] = useState(0);
    const [pageValidated,setPageValidated] = useState(true);
    const [formError,setFormError] = useState(false);
    const [formLoading,setFormLoading] = useState(false);
    const [criteria, setCriteria] = useState({});
    const [hiddenFields,setHiddenFields] = useState([]);
    
    useEffect(() => {
        showHideConditional(criteria,rules,hiddenFields,setHiddenFields);
    }, [criteria])
    
    const handleSubmit = async () => {
        setFormLoading(true);
        if(!validatePage(formPage,true) || !formProperties){
            setFormLoading(false);
            return;
        }
        const { csrf = null, honeypot = null, reCaptcha=null } = formProperties;
        const query = buildQuery(formData, freeform.handle,fieldTypes,reCaptcha.enabled);
        let variables = {
            honeypot: {
                name: honeypot?.name,
                value: honeypot?.value,
            },
            csrfToken: {
                name: csrf?.name,
                value: csrf?.token,
            },
            ...formData
        }
        if(reCaptcha.enabled){
            const token = await recaptchaRef.current.executeAsync();
            variables['reCaptcha'] = {
                name: reCaptcha?.name,
                value: token,
            }
        }
        const res = await runQuery(query,variables)
        if (res) {
            setFormLoading(false);
            if(!res.errors){
                navigate.push(selectedSite === 'global' ? '/' + complete : '/' + selectedSite + '/' + complete);
                return;
            }
            setFormError(true);
            console.error(res);
            
            //show errors from api
            res.errors.forEach(({ message }) => {
                const messages = JSON.parse(message);
                messages.forEach(message => {   
                    for (const [key, value] of Object.entries(message)) {
                        const errorLabel = document.querySelector(`.field-${key}-error`);
                        if (errorLabel) {
                            errorLabel.innerHTML = value;
                            errorLabel.style.display="flex";
                        }
                    }
                });
            });
        }

    };

    //Input change handlers

    const handleChange = (target,triggers = null) => {
        setPageValidated(true);
        hideSubmissionError();
        if (target.type === "checkbox" || target.type === "radio") {
            setFormData(p => { return { ...p, [target.name]: target.checked ? target.value : '' } })
        }
        else {
            setFormData(p => { return { ...p, [target.name]: target.value } })
        }
        if (triggers) {
            let temp = { ...criteria };
            triggers.forEach(item => {
                if (temp[item.trigger]) {
                    temp[item.trigger] = { ...temp[item.trigger], [item.hash]: target.type === "checkbox" ? `${target.checked  ? '1' : '' }` :target.value }
                } else {
                    temp = { ...temp, [item.trigger]: { [item.hash]: target.type === "checkbox" ? `${target.checked  ? '1' : '' }` :target.value } }
                }
            })
            setCriteria(temp);
        }
    }


    const handleRadioChange = (target, option, hasNoButtons,value,handle) => {
        if(value !== undefined){
            setFormData(p => { return { ...p, [handle]: value } });
            return;
        }
        hideSubmissionError();
        setPageValidated(true);
        setFormData(p => { return { ...p, [target.name]: option.label } });
        //Move to next page on radio change if form has no buttons.
        if (hasNoButtons) {
            setFormPage(r => ++r)
        }
    }

    const handleCheckboxGroup = (target,values,handle) => {   
        if(values){
            setFormData((prev) =>{return  {...prev,[handle]:values}});
            return;
        }  
        setPageValidated(true);
        hideSubmissionError(); 
        var checkboxGroup = [];
        if(formData[target.name]){
            checkboxGroup = [...formData[target.name]];
        }

        if (target.checked) {
            checkboxGroup.push(target.value);
        } else {
            checkboxGroup = checkboxGroup.filter((value) => value !== target.value);
        }
        setFormData((prev) =>{return  {...prev,[target.name]:checkboxGroup}});
    }

    const handleMultiSelect = (target) => {
        setPageValidated(true);
        hideSubmissionError();
        const options = target.options;
        let selected = [];
        for (let index = 0; index < options.length; index++) {
            if(options[index].selected){
                selected.push(options[index].value);
            }
            
        }
        setFormData((prev) =>{return  {...prev,[target.name]:selected}});
    }

    const handleFileChange = async (target) => {
        setPageValidated(true);
        hideSubmissionError();
        setFieldTypes(p => { return { ...p, [target.name]: target.type } });
        let files = formData[target.name] ?? [];
        for (let index = 0; index < target.files.length; index++) {
            const base64 = await convertBase64(target.files[index]);
            files.push({
                fileData:base64,
                filename:target.files[index].name,
                url:''
            })
        }
        setFormData(p => { return { ...p, [target.name]: files } });
        target.value = '';
    }

    //Front-end validation and also used with multi-page form to make sure all required fields are filled before move to next page.
    const validatePage = (page,submitBtn) => {
        let validated = true;
        const fields = document.querySelectorAll(`.form${freeform.id}page${page}`);
        for (let index = 0; index < fields.length; index++) {
            if(fields[index].required && !formData[fields[index].name]){
                fields[index].classList.add("input-error")
                const errorLabel = document.querySelector(`.field-${fields[index].name}-error`);
                errorLabel.innerHTML = "This field is required";
                errorLabel.style.display="flex";
                validated = false;
            }
        }
        if(validated && !submitBtn){
            setFormPage(r => ++r);
        }
        if(!validated){
            setPageValidated(false);
        }
        return validated;
    }

    const hideSubmissionError = () => {
        const errors = document.querySelectorAll('.form-field-error');
        if (errors) {
            errors.forEach(error => {
                error.style.display="none"
            });
        }
    };
    return (
        <form className='form-component'>
            {
                <div key={`formPage${formPage}`} className='form-page' id={`formPage${formPage}`}>
                    {layoutJson.composer.layout.length > 1 && <h1>{layoutJson.composer.properties[`page${formPage}`].label}</h1>}
                    {
                        layoutJson.composer.layout[formPage].map((row,rowIndex) => {
                            return (
                                <div className={`form-row ${(row.columns.length ===1 & hiddenFields.includes(row.columns[0])) ? 'hidden' : ''}`} key={row.id} id={row.id}>
                                    {row.columns.map((column) => {
                                        const field = layoutJson.composer.properties[column];
                                        if (rules) {
                                            field.show = true;
                                            if (rules[column]) {
                                                if (!Array.isArray(rules[column])) {
                                                    field.show = !rules[column].show;
                                                } else {
                                                    field.triggers = rules[column];
                                                }
                                            }
                                        }
                                        switch (field.type) {
                                            case 'html':
                                                return <div className='form-label' key={column} dangerouslySetInnerHTML={sanitizedData(field.value)} />
                                            case 'checkbox':
                                                return (
                                                    <div key={column}>
                                                    <Checkbox attributes={{
                                                    required: field.required,
                                                    name: field.handle,
                                                    defaultChecked: formData[field.handle] !== undefined ? formData[field.handle] === '' ? false : true : field.checked,
                                                    onChange: event => handleChange(event.target,field.triggers),
                                                    form_page:`form${freeform.id}page${formPage}`
                                                }}
                                                    label={field.label}
                                                    size={30} 
                                                    handleDefault={handleChange}
                                                    triggers={field.triggers}
                                                    formData={formData} />
                                                    <label className={`field-${field.handle}-error form-field-error`}></label>
                                                    </div>
                                                )
                                            case'checkbox_group':
                                                return (
                                                    <CheckboxGroup
                                                    key={column}
                                                    formPage={formPage}
                                                    field={field}
                                                    setFormData={setFormData}
                                                    formData={formData}
                                                    handleCheckboxGroup={handleCheckboxGroup}
                                                    formId={freeform.id}
                                                    />
                                                )
                                            case 'radio_group':
                                                return (
                                                    <RadioGroup
                                                    key={column}
                                                    field={field}
                                                    formPage={formPage}
                                                    formId={freeform.id}
                                                    handleRadioGroup={handleRadioChange}
                                                    hasNoButtons={layoutJson.composer.layout[formPage].length === 1}
                                                    formData={formData}
                                                    />
                                                )
                                            case 'select':
                                                return (
                                                    <Select key={column}
                                                        attributes={
                                                            {
                                                                column:column,
                                                                name: field.handle,
                                                                id: field.handle,
                                                                defaultValue: field.value ? field.value :formData[field.handle] ,
                                                                required: field.required,
                                                                onChange: event => handleChange(event.target,field.triggers),
                                                                form_page:`form${freeform.id}page${formPage}`,
                                                            }
                                                        }
                                                        field={field}
                                                        options={field.options}
                                                        handleDefault={handleChange}
                                                        triggers={field.triggers}
                                                    />
                                                )
                                            case 'multiple_select':
                                                return (
                                                    <Select key={column}
                                                        attributes={
                                                            {
                                                                name: field.handle,
                                                                id: field.handle,
                                                                defaultValue: formData[field.handle] ? formData[field.handle] :field.values,
                                                                required: field.required,
                                                                onChange: event => handleMultiSelect(event.target),
                                                                form_page:`form${freeform.id}page${formPage}`,
                                                                multiple:true
                                                            }
                                                        }
                                                        field={field}
                                                        options={field.options}
                                                        handleDefault={handleMultiSelect}
                                                    />
                                                )
                                            case 'textarea':
                                                return <TextArea key={column} attributes={{
                                                    column:column,
                                                    id: field.handle,
                                                    name: field.handle,
                                                    placeholder: field.label,
                                                    rows: field.rows ? field.rows : 10,
                                                    required: field.required,
                                                    defaultValue: formData[field.handle] ? formData[field.handle] :field.value,
                                                    onChange: event => handleChange(event.target),
                                                    form_page:`form${freeform.id}page${formPage}`,
                                                    rowid:row.id
                                                }} 
                                                hide={hiddenFields.includes(column)}
                                                handleDefault={handleChange}
                                                formData={formData} />
                                            case 'submit':
                                                //if multipages form
                                                if (layoutJson.composer.layout.length > 1) {
                                                    //if not first page
                                                    if (formPage > 0) {
                                                        // last page
                                                        if (formPage === layoutJson.composer.layout.length - 1) {
                                                            return (
                                                                <div key={column} className='buttons-wrapper'>
                                                                    {!field.disablePrev && <button className='button outline'  type='button' onClick={() => { setFormPage(r => --r) }}>{field.labelPrev}</button>}
                                                                    <button className='button outline'  type='button' onClick={handleSubmit}>{formLoading? formProperties?.loadingText : field.labelNext}</button>
                                                                </div>
                                                            )
                                                        }
                                                        // not first and not last page
                                                        return (
                                                            <div key={column} className='buttons-wrapper'>
                                                                {!field.disablePrev && <button className='button outline'  type='button' onClick={() => { setFormPage(r => --r)}}>{field.labelPrev}</button>}
                                                                <button className='button outline' type='button' onClick={() => {validatePage(formPage,false);}}>{field.labelNext}</button>
                                                            </div>
                                                        )
                                                    }
                                                    //first page
                                                    return <button className='button outline'  key={column} type='button' onClick={() => {validatePage(formPage,false)}}>{field.labelNext}</button>
                                                }
                                                return <button className='button outline'  key={column} type='button' onClick={handleSubmit}>{formLoading? formProperties?.loadingText : field.labelNext}</button>
                                            case 'rich_text':
                                                return <div className='form-text' key={column} dangerouslySetInnerHTML={sanitizedData(field.value)} />
                                            case 'mailing_list'://Email Marketing field
                                                return (
                                                    <Checkbox key={column} attributes={{
                                                        required: field.required,
                                                        name: column,
                                                        defaultChecked: formData[field.handle] ? formData[field.handle] : field.value,
                                                        onChange: event =>  setFormData(p => { return { ...p, ['mailingList_'+event.target.name]: event.target.checked ? 1 : 0 } }),
                                                        form_page:`form${freeform.id}page${formPage}`,
                                                        hidden:field.hidden,
                                                        rowid:row.id
                                                    }}
                                                        label={field.label}
                                                        size={30}
                                                        handleDefault={(target) => {setFormData(p => { return { ...p, ['mailingList_'+target.name]: target.checked ? 1 : 0} })}} 
                                                        formData={formData}/>
                                                )
                                            case 'file':
                                                return <FileInput key={column} attributes={
                                                    {
                                                        column:column,
                                                        type: field.type,
                                                        name: field.handle,
                                                        id: field.handle,
                                                        placeholder: field.label,
                                                        required: field.required,
                                                        defaultValue: formData[field.handle] ? formData[field.handle] :field.value ,
                                                        onChange: event => handleFileChange(event.target),
                                                        form_page:`form${freeform.id}page${formPage}`,
                                                        rowid:row.id,
                                                        multiple: field.fileCount > 1,
                                                        fileCount:field.fileCount,
                                                        accept:field.fileKinds ? field.fileKinds.map(item => {
                                                            switch(item){
                                                                case 'image':
                                                                    return 'image/*'
                                                                case 'video':
                                                                    return 'video/*'
                                                                case 'audio':
                                                                    return 'audio/*'
                                                                case 'word':
                                                                    return '.doc,.docx';
                                                                case 'pdf':
                                                                    return '.pdf';
                                                                case 'excel':
                                                                    return ' .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel';
                                                                case 'powerpoint':
                                                                    return 'application/vnd.ms-powerpoint';
                                                                case 'text':
                                                                    return 'text/plain, text/csv, and text/html'
                                                                case "compressed":
                                                                    return "application/zip"
                                                                default:
                                                                    return '' 
                                                            }
                                                        } ).join(',') : ''
                                                    }
                                                }
                                                hide={hiddenFields.includes(column)}
                                                handleDefault={handleFileChange}
                                                formData={formData}
                                                setFormData={setFormData}
                                                 />
                                            default:
                                                let btn;
                                                if(field.inputAttributes){
                                                    field.inputAttributes.forEach(item => {
                                                        if(item.attribute === "btn"){
                                                            btn = item;
                                                        }
                                                    });
                                                }
                                                return <Input key={column} attributes={
                                                    {
                                                        column:column,
                                                        type: field.type,
                                                        name: field.handle,
                                                        id: field.handle,
                                                        placeholder: field.label,
                                                        required: field.required,
                                                        defaultValue: formData[field.handle] ? formData[field.handle] :field.value ,
                                                        onChange: event => handleChange(event.target),
                                                        form_page:`form${freeform.id}page${formPage}`,
                                                        rowid:row.id
                                                    }
                                                }
                                                handleSubmit={handleSubmit}
                                                loading={formLoading}
                                                btn={btn}
                                                loadingText={formProperties && formProperties?.loadingText}
                                                hide={hiddenFields.includes(column)}
                                                handleDefault={handleChange}
                                                formData={formData}
                                                 />
                                        }
                                    })}
                                </div>
                            )
                        })
                    }
                    {/* {formSubmitted && <div className='form-success-message'><p>{formProperties.successMessage}</p></div>} */}
                    {/* {!pageValidated && <div className='form-validation-error'><p>Please make sure all required fields are filled and try submitting again.</p></div>}
                    {formError && <div className='form-validation-error'><p>{formProperties.errorMessage}</p></div>} */}
                    {layoutJson.composer.layout.length > 1 && <span className='page-indicator'>{formPage + 1}/{layoutJson.composer.layout.length}</span>}
                </div>

            }
                        <ReCAPTCHA
                ref={recaptchaRef}
                size="invisible"
                sitekey={process.env.REACT_APP_RECAPTCHE_SITE_KEY}
            />

        </form>
    )
}

export default FormComponent