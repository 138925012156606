import {useEffect, useRef } from 'react';
import Checkbox from './Checkbox';

export const CheckboxGroup = ({field,formPage,formId,handleCheckboxGroup,formData}) => {
    const ref = useRef(null);
    useEffect(() => {
        if(ref && handleCheckboxGroup && formData[field.handle] === undefined){
            handleCheckboxGroup(ref.current,field.values,field.handle);
        }
    },[ref])
    return (
        <div ref={ref} className='field-wrapper radio-group-wrapper'>
            {field.label && <label className='form-label' htmlFor={field.handle}>{field.label}</label>}
            {field.options.map((option, optionIndex) => {
                return (
                    <Checkbox key={`Page${formPage}Checkbox${optionIndex}`} attributes={
                        {
                            required: field.required,
                            name: field.handle,
                            defaultChecked: formData[field.handle] ?formData[field.handle]?.includes(option.label) : field.values.includes(option.label),
                            onChange: event => handleCheckboxGroup(event.target),
                            form_page:`form${formId}page${formPage}`,
                            defaultValue:option.label
                        }
                    }
                        label={option.label}
                        size={30}
                        formData={formData} />
                )
            })}
            <label className={`field-${field.handle}-error form-field-error`}></label>
        </div>
    )
}
