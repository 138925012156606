import React, { useContext } from 'react';
import { SiteContext } from '../widgets/SiteContext';
import { Link } from 'react-router-dom';

// site =undefined || null will use  selectedSite state as site
// site ='' will use the site in to param and set it as selectedSite
//site = "example" will use this as site and will set it as selectedSite

export const LinkReplace = ({ children, to, site, className,target='_blank',disabled = false ,external=false}) => {
  const { setSelectedSite, selectedSite } = useContext(SiteContext);
  let tempSite = site;
  if(site ==='internal'){
    tempSite = selectedSite;
  }
  const newSite = (tempSite !==undefined && (tempSite === '' || tempSite)) ? tempSite : selectedSite;
  if(external){
    return <a href={`${to.includes('http') ? '' : '//'}${to}`} target={target} className={className}>{children}</a>
  }
  return (
    <Link to={`${newSite === 'global' ? '/' : `${newSite === '' ? '/' : '/' + newSite + '/'}`}${to}`} onClick={
      (event) => {
        if(disabled){
          event.preventDefault();
          return;
        }
        if (tempSite !==undefined && tempSite !== null) {
          if (tempSite === '') {
            // if site included in uri , like in menu
            const uriArray = to.split('/');
            const uriSite = ['thrive','connect','accelerate'].includes(uriArray[0]) ? uriArray[0] : 'global';
            setSelectedSite(uriSite);
          }
          else {
            setSelectedSite(tempSite)
          }
        }
      }} className={className}>{children}</Link>
  )
}
