import React, { useContext, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SiteContext } from './SiteContext';
import GetData from '../utils/api';
import { sanitizedData } from '../utils/sanitize';
import { LinkReplace } from '../components/LinkReplace';

function Footer() {
  const { selectedSite } = useContext(SiteContext);
  const location = useLocation();
  const siteValue = (selectedSite === "global" || selectedSite === '') ? '' : '/' + selectedSite;
  const { data, error } = GetData(`${process.env.REACT_APP_CRAFT_URL}/api/get/footer-test.json`);

  const fields = useMemo(() => {
    let blocks = {}
    if (data) {
      data.data.forEach(item => {
        if (item) {
          blocks = { ...blocks, [item.title]: item }
        }
      });
      return blocks
    }
  }, [data])


  if (error) {
    console.error('Error fetching menu data:', error);
  }

  const getMenuUri = (menuUri) => {
    return menuUri === '__home__' ? '/home' : `${siteValue}/${menuUri}`;
  };

  return (
    <footer>
      <div className="inner">
        <div className="top">
          <div className="logo">
            <Link to='/home'>
              <img className="logo-img" src={fields?.FooterLogo?.url} alt="Scotch Global" />
            </Link>

            <img className="grown-from" src="/assets/images/logo/scotchcollege.svg" alt="Scotch College" />
          </div>

          <div className="socialbox">

            {fields?.Contact && <div className="contacts">
              {
                fields?.Contact?.children?.map((item,index) => {
                  if(item.title.match(/([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi)){
                    return <p key={item.title + index}><a href={'mailto:'+item.title}>{item.title}</a></p>
                  }
                  return <p key={item.title + index}><a href={'tel:'+item.title}>{item.title}</a></p>
                })
              }
            </div>}

            <ul className="social">
              {fields?.Socials?.children?.map((item, i) => (
                <li key={i}>
                  <a
                    target={item.blank ? "_blank" : undefined}
                    social={item.title.toLowerCase()}
                    href={item.url}
                  >
                    {item.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>

          {data?.data?.map((item, i) => {
            if (item.title.toLowerCase().includes('menu') && item.children) {
              return (
                <div key={i} className="menubox">
                  <ul className="menu" key={i}>
                    {item.children?.map((itemi, i) => (
                      <li key={i}>
                        <LinkReplace
                          to={itemi.type ==='CustomType' ? itemi.url : itemi.uri}
                          site=''
                          external={itemi.type ==='CustomType' ?true : false}
                        >
                          {itemi.title}
                        </LinkReplace>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            }
          }

          )}
        </div>
        
        <div className="extra">
          <p>Scotch Global would like to acknowledge all the First Nations peoples of Australia. We would like to give respect to the Wadjuk Noongar people, one of 14 groups that make up the Noongar nation. They are the traditional custodians of the lands and waters on which our campuses and offices stand, and we feel privileged to be able to work and live on this place.</p>
          <p>Noongar knowledges remains because of learning practices that have taken place on these lands for thousands of years and are still being passed on today.</p>
        </div>     
        
        
        {fields && <div className="bottom" >
          {fields.FooterLine?.children?.map((item,index) => {
            if(index < fields.FooterLine?.children.length-1 ){
              return <div key={item.title + index}>{item.type ==='CustomType' ? <a href={item.url} target={item.blank ? "_blank" : undefined}>{item.title}</a>: <p >{item.title}</p>} <p className="mobileno">|</p></div>
            }
            return <div key={item.title + index}>{item.type ==='CustomType' ? <a href={item.url} target={item.blank ? "_blank" : undefined}>{item.title}</a>: <p >{item.title}</p>}</div>
          })}
        <p className="dicky">Made with love by <a href="https://dickytall.com/" target="_blank">Dickytall &amp; Sons</a></p></div>}
      </div>
    </footer>
  );
}

export default Footer;
