import {useEffect, useRef } from 'react';
import Checkbox from './Checkbox';

export const RadioGroup = ({field,formPage,formId,handleRadioGroup,hasNoButtons,formData}) => {
    const ref = useRef(null);
    useEffect(() => {
        if(ref && handleRadioGroup && formData[field.handle] === undefined){
            handleRadioGroup(ref.current,null,null,field.value,field.handle);
        }
    },[ref])
    return (
        <div ref={ref} className='field-wrapper radio-group-wrapper'>
            {field.label && <label className='form-label' htmlFor={field.handle}>{field.label}</label>}
            {field.options.map((option, optionIndex) => {
                return (
                    <Checkbox key={`Page${formPage}Option${optionIndex}`}
                        attributes={
                            {
                                required: field.required,
                                name: field.handle,
                                id: option.label,
                                type: "radio",
                                defaultChecked: formData[field.handle] ? formData[field.handle] === option.label : field.value === option.label,
                                form_page: `form${formId}page${formPage}`,
                                onChange: (event) => { handleRadioGroup(event.target, option, hasNoButtons,null,null) }
                            }
                        }
                        size={30}
                        label={option.label}
                        formData={formData} />
                )
            })}
            <div className={`field-${field.handle}-error form-field-error`}></div>
        </div>
    )
}
