import React, { useEffect, useState} from 'react';
import { Widget } from '../components/Widget';
import { FilterComponent } from '../components/FilterComponent';
import useWindowWidth from '../hooks/useWindowWidth';
import { Card } from '../components/Card';

const TeamList = ({ data }) => {
  const windowWidth = useWindowWidth();
  const [showFilters,setShowFilters] = useState(false)
  const [filterdItems,setFilterdItems] = useState([]);
  const [filters,setFilters] = useState(null);

    useEffect(() => {
      if(data.teamList.length > 0){
        if(filters){
          setFilterdItems(filter(data.teamList[0].members));
        }else{
          setFilterdItems(data.teamList[0].members);
        }
      }
    },[filters])
  
    const filter = (data) => {
      return data.filter((i) => {
        const itemCategories = i.categories ? Object.values(i.categories).map((cat) => cat.slug) : [];
        if(filters){
          let found = false;
          let tempFilters = [];
          Object.values(filters).forEach(item=> {
            if(item){
              tempFilters = [...tempFilters,item]
            }
          });
          // itemCategories.forEach(cat => {
          //   if(filters.includes(cat)){
          //     found = true;
          //   };
          // });
          found = tempFilters.every(filter => {
            if(filter.length === 0 ){
              return true;
            }
            return filter.some(item => itemCategories.includes(item));
          })
          return found;
        }
        return true;
      })
    }

  return (
    <Widget className='teamlist'>
      {data.teamList.length > 0 && <div className='inner'>
        <div className='top'>
          {data.teamList[0].maintitle && <h3>{data.teamList[0].maintitle}</h3>}
          {(data.categories && !Array.isArray(data.categories)) && <FilterComponent categories={data.categories} filters={filters} setFilters={setFilters} setShowFilters={setShowFilters} windowWidth={windowWidth} title={data.teamList[0].maintitle}/>}
        </div>       
        {(windowWidth < 744  && showFilters)&& <FilterComponent onlyWrapper={true} categories={data.categories} filters={filters} setFilters={setFilters} setShowFilters={setShowFilters} windowWidth={windowWidth}/>} 
        <ul>
          {filterdItems.map((item, index) => {
            return (
              <Card key={item.title + index} title={item.title} uri={item.uri} imageURL={item.image.url ? item.image.url : item.image} imageFocusX={item.image?.focus.x} imageFocusY={item.image?.focus.y} categories={item.categories} type='staff'/>
            )
          })}
        </ul>
      </div>}
    </Widget>
  )
};

export default TeamList;