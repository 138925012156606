import React from 'react';

const Maintitle = ({ data }) => 
  <div className="widget maintitle">
    <div className="inner">
      <h2>{data.maintitle}</h2>
    </div>
  </div>
;

export default Maintitle;