import React from 'react';
import Video from './Video';
import Breadcrumb from './Breadcrumb';

import { sanitizedData } from '../utils/sanitize';
import { Widget } from '../components/Widget';
import { Quote } from '../components/Quote';


const Article = ({ data, bread }) => {
  return (
    <Widget className="article">
    {
      data.contenttext.map((item,i) => {
        switch(item.type){
          case "starter":
            return (
            <div ani="y" key={`article_${item.type}${i}`} className='starter'>
              <Breadcrumb bread={bread} />
              
              <div className="text">
                <h1>{item.mainTitle}</h1>
                <h2>{item.subtitle}</h2>
              </div>
            </div>
            );
          case "paragraph":
            return <p ani="y" className='paragraph' key={`article_${item.type}${i}`} dangerouslySetInnerHTML={sanitizedData(item.paragraph)}/>
          case "quote":
            return (
              <Quote ani="y" key={`article_${item.type}${i}`} quote={item.quotes[0]?.quote} author={item.quotes[0]?.quoteAuthor}/>
            );
          case "highlightParagraph":
            return <p ani="y" key={`article_${item.type}${i}`} className='highlightParagraph' dangerouslySetInnerHTML={sanitizedData(item.paragraph)}/>
          case "image":
            return <img loading="lazy" ani="y" className={`image ${item.size}`} key={`article_${item.type}${i}`} style={{objectPosition: `${item.image.focus?.x * 100}% ${item.image.focus?.y * 100}%` }} src={item.image.m} alt={item.type+i}/>;
          case "embed":
            return <Video ani="y" className="video" key={`article_${item.type}${i}`} data={item}/>
          default:
            return null;
        }
      })
    }
  </Widget>
  )
};

export default Article;