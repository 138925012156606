import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Widget } from '../components/Widget';

const Imageslider = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % data.slides.length);
    }, 1500);

    setTimer(interval);

    return () => {
      clearInterval(interval);
    };
  }, [data.slides.length]);

  const handleMouseEnter = () => {
    clearInterval(timer);
  };

  const handleMouseLeave = () => {
    startTimer();
  };

  const handleDotClick = (index) => {
    setActiveIndex(index);
    clearInterval(timer);
    startTimer();
  };

  const handlePrevClick = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + data.slides.length) % data.slides.length);
    clearInterval(timer);
    startTimer();
  };

  const handleNextClick = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % data.slides.length);
    clearInterval(timer);
    startTimer();
  };

  const startTimer = () => {
    clearInterval(timer);
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % data.slides.length);
    }, 1500);
    setTimer(interval);
  };

  
  return (
    <Widget className="imageslider">
      <span className="prev" onClick={handlePrevClick}>&laquo;</span>
      <span className="next" onClick={handleNextClick}>&raquo;</span>
      <ul className="slides">
        {data.slides.map((slide, i) => (
          <li
            key={i}
            className={i === activeIndex ? 'active' : ''}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          > 
            <Link to={`/${slide.slidelink}`} className="overlaylink">{slide.maintitle}</Link>
            <div className="overlay">
              <h2>{slide.maintitle}</h2>
              <h3>{slide.subtitle}</h3>
            </div>
            <img width="320" src={slide.image} alt={i} loading='lazy'/>
          </li>
        ))}
      </ul>
      <ul className="dots">
        {data.slides.map((slide, i) => (
          <li
            key={i}
            className={i === activeIndex ? 'active' : ''}
            onClick={() => handleDotClick(i)}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {i}
          </li>
        ))}
      </ul>
    </Widget>
  );
};

export default Imageslider;
