import { useEffect } from "react";
import TagManager from 'react-gtm-module'

export const Gtag = ({data}) => {
    const conversionEventData = {
        dataLayer:{
            event:'conversion',
            send_to:data?.plainText
        }
      };
    useEffect(() => {
        TagManager.dataLayer(conversionEventData);
    },[data]);
  return (
    <div style={{display:'none'}}>Gtag</div>
  )
}
