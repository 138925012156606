import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import GetData from '../utils/api';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useSwiper } from 'swiper/react';
import { Widget } from '../components/Widget';
import { SiteContext } from './SiteContext';
import useWindowWidth from '../hooks/useWindowWidth';
import { LinkReplace } from '../components/LinkReplace';

import 'swiper/css';

function SwiperButtons({ moreButton }) {
  const { selectedSite } = useContext(SiteContext);
  
  let newsUrl = '/news';

/*
  if (selectedSite === 'global') {
    newsUrl = '/news';
  } else {
    newsUrl = '/' + selectedSite + '/news';
  }
*/
  
  const swiper = useSwiper();
  
  return (
    <div className="controls">
      <button className="arrow left" onClick={() => swiper.slidePrev()}>L</button>
      
      {(moreButton && moreButton.length !== 0 )&& <LinkReplace className="button center"
         to={moreButton[0].type ==='CustomType' ? moreButton[0].url : moreButton[0].uri}
         site={moreButton[0].type}
         external={moreButton[0].type ==='CustomType' ?true : false}
       >
         {moreButton[0].title}
       </LinkReplace>}

      <button className="arrow right" onClick={() => swiper.slideNext()}>R</button>
    </div>
  )
}

const Newsslider = ({ data: object,match }) => {


  const { selectedSite } = useContext(SiteContext);
  const siteValue = (selectedSite === "global" || selectedSite === '') ? '' : '/' + selectedSite;
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const { data, loading, error } = GetData(`${process.env.REACT_APP_CRAFT_URL}${siteValue}/api/get/items/` + object.slugs + `/6.json?page=${page}`);
  const windowWidth = useWindowWidth();
  const [currentPage,setCurrentPage] = useState(object.slugs[0][0])

  useEffect(() => {
    if(currentPage !== object.slugs[0][0]){
      setItems([]);
      setCurrentPage(object.slugs[0][0]);
    }
  },[match])

  useEffect(() => {
    if (!loading) {
      setItems(prev => [...prev, ...data.data]);
      setTotalPages(data.meta.pagination.total_pages);
    }
  }, [loading, data, error]);

  const handleLoadMore = () => {
    if (page < totalPages && !loading) {
      setPage(prevPage => prevPage + 1);
    }
  };

  return (
    <Widget className={`newsslider  ${object.nobg === "true" ? 'nobg' : ''}`}>
      <div className="inner">
        <h3>{object.title}</h3>
        {
          windowWidth < 743
            ? (
              <div className="newslist">
                {items.slice(0, 3).map((item, i) => (
                  <div className="item" key={i}>
                    <Link to={`/${item.uri}`} className="clicker">{item.title}</Link>
                    <img loading="lazy" src={item.images.s} alt={item.title} />
                    <div className="message">
                      <h5>{item.title}</h5>
                      <time dateTime={item.postdate}>{item.postdate}</time>
                      <p>{item.maindescription}</p>
                    </div>
                  </div>
                ))}
              </div>
            )
            : (
              <Swiper
                spaceBetween={50}
                slidesPerView={2.5}
                onSlideChange={handleLoadMore}
              >
                {items.map((item, i) => (
                  <SwiperSlide ani="y" key={i}>
                    <div className="item">
                      <Link to={`/${item.uri}`} className="clicker">{item.title}</Link>
                      <img loading="lazy" src={item.images.s} alt={item.title} style={{objectPosition: `${item.images.focus?.x * 100}% ${item.images.focus?.y * 100}%` }} />
                      <div className="message">
                        <h5>{item.title}</h5>
                        <time dateTime={item.postdate}>{item.postdate}</time>
                        <p>{item.maindescription}</p>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
                <SwiperButtons moreButton={object.moreButton} />
              </Swiper>
            )
        }
      </div>
    </Widget>
  );
};

export default Newsslider;
