import {useEffect,useRef} from 'react'

const TextArea = ({attributes,handleDefault,hide,formData}) => {
  const ref = useRef(null);

  useEffect(() => {
      if(ref && handleDefault && formData[attributes.name] === undefined ){
          handleDefault(ref.current);
      }
  },[ref])
  return (
    <div className={`field-wrapper ${hide ? 'hidden':''}`} id={`condition${attributes.column}`}>
        <textarea ref={ref} className={`form-textarea field-input ${attributes.form_page}`} {...attributes} placeholder={`${attributes.placeholder}${attributes.required ? '*' : ''}`} />
        <label className={`field-${attributes.name}-error form-field-error`}></label>
    </div>
  )
}

export default TextArea