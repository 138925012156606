import React, { useState } from 'react';

const useAccordion = (length) => {
    const [accordion, setAccordion] = useState(Array.from({ length: length }, () => {
        if(length > 1)
        {
            return false;
        }
        return true;
    }));

    return {accordion,setAccordion}
}

const AccordionItem = ({index,head,body=null,accordionHook,multiple = false}) => {

    const handleClick = (i) => {
        accordionHook.setAccordion((prev) => prev.map((item, index) => {
            if (i === index) {
                return !item;
            }
            if (!multiple) {
                return false;
            }
            return item;
        }))
    }

    return (
        <li  className={`accordion-item ${accordionHook.accordion[index] ? 'accordion-item-open' : 'accordion-item-closed'} ${!body ? 'hover' : ''}`}>
            <div className='accordion-head'>
                {head}
                
                {(accordionHook.accordion.length > 1 && body) && <a onClick={() => handleClick(index)} className='clicker'>Open</a>}
                {(accordionHook.accordion.length > 1 && body) && <a onClick={() => handleClick(index)} className='accordion-toggle'>^</a>}
            </div>
            { body && <div className='accordion-body'>
                {body}
            </div>}
        </li>
    )
}
const AccordionComponent = ({children,title}) => {
    
    return (
        <div className='accordion-component'>
        <h2>{title}</h2>
        <ul>
            {children}
        </ul>
        </div>
    )
}

export {AccordionComponent,AccordionItem,useAccordion}