import { LinkReplace } from '../components/LinkReplace';

export const Card = ({ title, description, date,expiryDate, uri, imageURL, imageFocusX, imageFocusY, alt = "", type, categories,emphasisLine,section,site }) => {
    const filterdCategories = categories ? Object.values(categories).filter(item => {
        if (item.parent) {
            if (type === 'staff') {
                return item.parent.slug === 'year-level'
            }
            return item.parent.slug === 'discipline' || item.parent.slug === 'course-level'
        }
        return false;
    }) : null;
    let added = 0;
    return (
        <li ani="y" className='card-component' style={!imageURL ? {display:'none'} : undefined}>
            {type ==='search' ? <LinkReplace to={uri} site={site?.handle} className="clicker">{title}</LinkReplace> : <LinkReplace to={uri} className="clicker">{title}</LinkReplace>}
            <img src={imageURL} loading="lazy" style={{objectPosition: `${imageFocusX * 100}% ${imageFocusY * 100}%` }} alt={alt} />
            <div className="message">
                <h5>{title}</h5>
                {(emphasisLine && type === 'courses') && <span className='emphasisLine'>{emphasisLine}</span>}
                {(date && (type === 'news' || type === 'search')) && <time dateTime={date}>{date}</time>}
                {(date && type === 'events') && <time dateTime={expiryDate}>{expiryDate}</time>}
                {description && <p>{description}</p>}
                <div className={`categories ${type === 'courses' ? 'courses' : ''}`}>
                    {(type === 'staff' && filterdCategories) ? <h6>CURRENTLY TEACHING</h6> : ''}
                    {(filterdCategories && (type !== 'news' || type !== 'search')) && <div className='tags'>
                        {filterdCategories.map((item, index) => {
                            if (index <= 3) {
                                added++;
                                return (
                                    <span key={item.title + index} className='tag'>{item.title}</span>
                                )
                            }
                        })}
                    </div>}
                    {filterdCategories && (filterdCategories.length - added > 0 ? <span className='overflow'>+{filterdCategories.length - added } more</span> : '')}

                    {  type === 'search' && <div className='tags search'>
                    <span className='tag'>{site?.name}</span>
                    <span className='tag'>{section}</span>
                    </div>}
                </div>
            </div>
        </li>
    )
}
