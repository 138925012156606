import {useEffect,useRef} from 'react'

const FileInput = ({ attributes,hide,handleDefault,formData,setFormData}) => {
  const ref = useRef(null);
  useEffect(() => {
    if(ref && handleDefault && formData[attributes.name] === undefined){
        handleDefault(ref.current);
    }
},[ref])

  return (
    <div className={`field-wrapper file ${hide ? 'hidden':''}`} id={`condition${attributes.column}`}>
      <div className='input-wrapper'>
        <input ref={ref} className={`form-input field-input ${attributes.form_page}`}   {...attributes} placeholder={`${attributes.placeholder}${attributes.required ? '*' : ''}`} />
      </div>
      <button type='button' className='button outline'  onClick={()=>{
        if(formData[attributes.name].length < attributes.fileCount){ref.current.click()}}
        } 
        disabled={formData[attributes.name]?.length >= attributes.fileCount}> {attributes.placeholder} </button>
      { (formData[attributes.name] && formData[attributes.name].length) ? 
      <ul className='files'>
        {
            formData[attributes.name].map(item => <li className='removeFile' key={item.filename} data-file={item.filename} onClick={(event) => {
                const files = formData[attributes.name];
                setFormData(p => { return { ...p, [attributes.name]: files.filter(item => item.filename !== event.target.dataset.file) } });
            }}>{item.filename}, Click to remove</li> )
        }
      </ul> : <></>}
      <label className={`field-${attributes.name}-error form-field-error`}></label>
    </div>
  )
}

export default FileInput