import React, { useMemo, useState, useEffect } from 'react';
import { Widget } from '../components/Widget';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { useSwiper } from 'swiper/react';

import 'swiper/css';
import { Quote } from '../components/Quote';

function SwiperButtons() {
  const swiper = useSwiper();
  return (
    <div className="controls">
      <button className="arrow left" onClick={() => swiper.slidePrev()}>L</button>
      <button className="arrow right" onClick={() => swiper.slideNext()}>R</button>
    </div>
  )
}

const Quotes = ({ data }) => {
  const [slidesPerView, setSlidesPerView] = useState(window.innerWidth < 743 ? 1.09 : 1.99999);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 743) {
        setSlidesPerView(1.09);
      } else {
        setSlidesPerView(2);
      }
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const quotes = useMemo(() => {
    let items = data.quotes ?? [];
    if (items.length > 0) {
      while (items.length < 5) {
        items = [...items, ...data.quotes]
      }
    }
    return items;
  }, [data]);

  return (
    <Widget className="quotes nobg">
      {quotes && <div className='inner'>
        <Swiper
          slidesPerView={slidesPerView}
          spaceBetween={100}
          loop={true}
          centeredSlides={true}
          modules={[Autoplay]}
          autoplay={
            {
              delay: 6000,
              pauseOnMouseEnter: true
            }
          }
        >
          {
            quotes.map((item, index) => (
              <SwiperSlide key={item.title + index}>
                <div className='item'>
                  <Quote quote={item.quote} author={item.quoteAuthor} />
                </div>
              </SwiperSlide>
            ))
          }
          <SwiperButtons />
        </Swiper>
      </div>}
    </Widget>
  );
};

export default Quotes;
