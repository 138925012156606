import React, { useMemo, useRef, useState, useEffect } from 'react'
import { Widget } from '../components/Widget';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules'
import useWindowWidth from '../hooks/useWindowWidth';

import 'swiper/css';
import 'swiper/css/autoplay'

export const LogoSlider = ({ data }) => {
    const swiperRef = useRef();
    const windowWidth = useWindowWidth();

    

    const slides = useMemo(() => {
        let items = data.logosliders ?? [];
        if(items.length > 0){
            while (items.length < 10) {
                items = [...items, ...data.logosliders]
            }
        }
        return items
    }, [data]);


    const slidesMobile = useMemo(() => {
        let items = data.logosliders ?? [];
        if(items.length > 0){
            while (items.length < 1) {
                items = [...items, ...data.logosliders]
            }
        }
        return items
    }, [data]);




    const divClassName = data.logosliders && data.logosliders.length === 5 ? 'inner' : 'fullinner';

    return (
        <Widget className='logoslider'>
            {windowWidth <= 743 ? (
                <div className="logobarmobile">
                    {data.title && <h3>{data.title}</h3>}
                        {
                            slidesMobile.map((item, index) => {
                                return (
                                  <img loading='lazy' src={item.image.url} className='mobileitem' />
                                )
                            })
                        }

                </div>
            ) : (
                <div className={divClassName}
                    onMouseEnter={() => swiperRef.current?.swiper?.autoplay.stop()}
                    onMouseLeave={() => swiperRef.current?.swiper?.autoplay.start()}>
                    {data.title && <h3>{data.title}</h3>}
                    <Swiper
                        ref={swiperRef}
                        slidesPerView={data.logosliders.length > 5 ? 5 : data.logosliders.length}
                        loopedSlides={5}
                        spaceBetween={0}
                        loop={data.logosliders.length > 5 ? true : false}
                        speed={5000}
                        centeredSlides={data.logosliders.length > 5 ? true : false}
                        allowTouchMove={false}
                        modules={[Autoplay]}
                        autoplay={
                            data.logosliders.length > 5 ? {
                                delay: 0
                            } : undefined
                        }
                    >
                        {
                            slides.map((item, index) => {
                                return (
                                    <SwiperSlide key={'logoslider' + index}>
                                        <img loading='lazy' src={item.image.url} className='item' />
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            )}
        </Widget>
    )
}
