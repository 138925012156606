import React from 'react';
import { LinkReplace } from '../components/LinkReplace';
import { AccordionComponent, AccordionItem, useAccordion } from '../components/AccordionComponent';
import { Widget } from '../components/Widget';
import { sanitizedData } from '../utils/sanitize';

const ScheduleList = ({ data }) => {

  const accordionHook = useAccordion(data.bodySchedules && data.bodySchedules.length);

  return (
    <Widget  className="schedulelist">
      <div className='inner'>
        <AccordionComponent title={data.title}>
          {
            data.bodySchedules.map((item,index) => {
              const doc = new DOMParser().parseFromString(sanitizedData(item.richtext).__html.replace(/\n[ ]*/g, ""),'text/html');
              const tables = Array.from(doc.querySelectorAll('table'));
              tables.forEach(table => {
                const wrapper = doc.createElement('div');
                wrapper.className = 'table';
                table.parentNode.insertBefore(wrapper, table);
                wrapper.appendChild(table);
              });
              const htmlString = new XMLSerializer().serializeToString(doc).replace(/<(body|head|html)\b[^>]*>|<\/(body|head|html)>/gi, "");
              const date = item.date ? new Date(item.date) : null;
              return (
                <AccordionItem key={item.title+index}
                index={index}
                accordionHook={accordionHook}
                multiple={false}
                head={
                  <div className={`schedule-head ${!item.richtext ? 'btn' : ''}`}>
                    {item.day && <h4>{item.day}</h4>}
                    {item.date && <div className='date'><span>{date.toLocaleDateString("en-AU",{weekday:"long",day:"numeric",month:"long"}).replace(",","")}</span></div>}
                    {item.title && <span className='title'>{item.title}</span>}
                    {item.price && <span className='price'>{item.price}</span>}
                    {!item.richtext  && <LinkReplace to={item.uri} className="button outline">More info</LinkReplace>}
                  </div>
                  }
                body={
                  item.richtext ?
                  <div className='schedule-body' dangerouslySetInnerHTML={{__html:htmlString}} /> : null
                  }
                />
              )
            })
          }
        </AccordionComponent>
      </div>
    </Widget>
  );
}

export default ScheduleList;