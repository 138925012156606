import React from 'react';
import { Widget } from '../components/Widget';
import { LinkReplace } from '../components/LinkReplace';

const Imagebox = ({ data }) => (
  <Widget className="imagebox">
    <div className="inner">
      <ul className="slides">
        {data.slides.map((slide, i) => (
          slide.type === 'title' ? (
            <li key={i} className="title">
              <h3>{slide.title}</h3>
            </li>
          ) : (
            <li key={i} ani={i} className="img">
              {slide.url && slide.url.length !== 0 ? (
                <LinkReplace to={slide.url} external={slide.type === 'external' ? true : false}>
                  <img loading='lazy' width="320" src={slide.image} alt="" />
                </LinkReplace>
              ) : (
                <img loading='lazy' width="320" src={slide.image} alt="" />
              )}
            </li>
          )
        ))}
      </ul>
    </div>
  </Widget>
);

export default Imagebox;