import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { SiteContext } from './SiteContext';

function Siteswitch() {
  const { setSelectedSite } = useContext(SiteContext);

  const handleSiteClick = (siteName) => {
    setSelectedSite(siteName);
    document.body.setAttribute('site', siteName);
  };

  return (
    <div className="platforms">
      <Link to="/home" onClick={() => handleSiteClick('global')}>Global</Link>
      <Link to="/connect/home" onClick={() => handleSiteClick('connect')}>Connect</Link>
      <Link to="/thrive/home" onClick={() => handleSiteClick('thrive')}>Thrive</Link>
      <Link to="/accelerate/home" onClick={() => handleSiteClick('accelerate')}>Accelerate</Link>
    </div>
  );
}

export default Siteswitch;