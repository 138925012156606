import React, {useMemo} from 'react';
import { Widget } from '../components/Widget';
import Breadcrumb from './Breadcrumb';
import { LinkReplace } from '../components/LinkReplace';
import { sanitizedData } from '../utils/sanitize';

const HeadSplit = ({ data, bread }) => {
  const fields = useMemo(() => {
    let dataObj={};
    data.forEach(item => {
      dataObj = {...dataObj,[item.type]:item}
    });
    return dataObj
  },[data])
  
  return (
    <Widget className="headsplit">    
        <div className={`inner ${fields.pattern ? '' : 'no-pattern'}`}>
          <div className="left">
            
            {fields.breakcrumb && <Breadcrumb bread={bread} />}

            {fields.maintext && <>
            <h1 ani="y" style={fields.pattern && !fields.mainimage ? undefined : {backgroundImage:`url(${fields.mainimage.images[0].m})`}}>{fields.maintext.title}</h1>

            <p ani="y" dangerouslySetInnerHTML={sanitizedData(fields.maintext.description)}></p>
            
            {(fields.maintext.tagline) && <div ani="y" className="tags extra"><span>{fields.maintext.tagline}</span></div>}
            
            </>}        
            {fields.tag && <div ani="y" className="tags">
              {fields.tag.tags.map((tag,i) => {
                return <LinkReplace key={tag.title + i} to={tag.uri} disabled={true}>{tag.title}</LinkReplace>;
              })}
            </div>}
            {
              fields.buttons && 
              <div ani="y" className="buttons">
                {
                  fields.buttons.buttons.map((button, i) => {
                    return (
                      <LinkReplace 
                        key={button.title + i} 
                        to={button.type === 'external' ? button.url : button.uri} 
                        className={"button " + button.style}
                        external={button.type === 'external' ? true : false}
                        site={(button.type !=='external') ? button.type : ''}
                      >
                        {button.title}
                      </LinkReplace>
                    );
                  })
                }
              </div>
            }
            {(fields.logo?.images && fields.logo?.externalLink == null && fields.logo?.images?.length !== 0) && <img className='logo' alt={fields.logo.title} src={fields.logo.images[0].s}/>}
            {(fields.logo?.images && fields.logo?.externalLink !== null && fields.logo?.images?.length !== 0) && <a href={fields.logo?.externalLink} target="_blank"><img className='logo' alt={fields.logo.title} src={fields.logo.images[0].s}/> </a>}
          </div>
          
          <div className="right">
            {fields.mainimage && <div className="img"><img src={fields.mainimage.images[0].m}  style={{objectPosition: `${fields.mainimage.images[0].focus?.x * 100}% ${fields.mainimage.images[0].focus?.y * 100}%` }} alt={fields.mainimage.title} /></div>}
            {fields.pattern && <div className="pattern"></div>}
          </div>
        </div>
    </Widget>
);
}

export default HeadSplit;