import FormComponent from "../components/FormComponent";
import { Widget } from '../components/Widget';
import { SiteContext } from '../widgets/SiteContext';
import { useContext } from "react";
import GetData from '../utils/api';

const Newsletter = ({ data:{forms} }) => {
  const {selectedSite } = useContext(SiteContext);
  const { data } = GetData((process.env.NODE_ENV !== 'development' && forms[0].freeform?.id) && `${process.env.REACT_APP_CRAFT_URL}/freeform/form/properties/${forms[0].freeform.id}`);
  return (
    <Widget className={`newsletter site-${selectedSite}`}>
      <div className='left'>
        <h3>{forms[0].maintitle}</h3>
        <h4>{forms[0].description}</h4>
      </div>
      <div className='right'>
        {<FormComponent freeform={forms[0].freeform} complete={forms[0].complete} formProperties={data}/>}
      </div>
    </Widget>
  )
};

export default Newsletter;