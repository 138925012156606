import React, { useRef, useState } from 'react';
import useInViewPort from '../hooks/useInViewPort';

const Video = ({ data }) => {
  const [visibleOverlay, setVisibleOverlay] = useState(true);
  const widgetRef = useRef(null);
  const iframeRef = useRef(null);
  const isVisible = useInViewPort(widgetRef);

  const handlePlay = () => {
    setVisibleOverlay(false);
    // iframeRef.current.contentWindow.postMessage(JSON.stringify({
    //   "event": "command",
    //   "func": "playVideo"
    // }), '*')

  }

  return (
    <section ref={widgetRef} className={`widget video ${isVisible ? 'start' : ''}`} data-src={data.embed}>
      <button className="play" style={{ display: `${!visibleOverlay ? "none" : "block"}` }} onClick={handlePlay}>PLay</button>
      <img loading="lazy" style={{ display: `${!visibleOverlay ? "none" : "block"}` }} src={data.preview} alt='overlay' width={1280} height={720}/>
      <iframe ref={iframeRef} title="video" src={`${!visibleOverlay ? data.embed+'?autoplay=1&rel=0' : 'about:blank'}`} allow="autoplay" ></iframe>
    </section>
  );
};

export default Video;