import React, { useState, useEffect, useContext } from 'react';
import GetData from '../utils/api';
import { Widget } from '../components/Widget';
import { SiteContext } from './SiteContext';
import { FilterComponent } from '../components/FilterComponent';
import useWindowWidth from '../hooks/useWindowWidth';
import { Card } from '../components/Card';

const Itemlist = ({ object = null, search = null, match }) => {
  const onlySelectedItems = object?.entrySelect ? (object?.entrySelect?.length === 0 ? true : object?.exclude) : true;
  const { selectedSite } = useContext(SiteContext);
  const siteValue = (selectedSite === "global" || selectedSite === '') ? '' : '/' + selectedSite;
  const [items, setItems] = useState([]);
  const [totalShown, setTotalShown] = useState(6);
  const [filters, setFilters] = useState(null);
  const [showFilters, setShowFilters] = useState(false)
  const { data, loading, error } = GetData((search === null && onlySelectedItems) && `${process.env.REACT_APP_CRAFT_URL}${siteValue}/api/get/items/${object.slugs}/999.json`);
  const { data: searchData, loading: searchLoading, error: searchError } = GetData((search !== null && search !== 'search') && `${process.env.REACT_APP_CRAFT_URL}${siteValue}/api/search.json?q=${search}`);
  const { data: catData, loading: catLoading, error: catError } = GetData((search !== null && search !== 'search') && `${process.env.REACT_APP_CRAFT_URL}${siteValue}/api/get/category.json`);
  const [currentPage, setCurrentPage] = useState(object?.slugs[0][0])
  const windowWidth = useWindowWidth();
  const [searchValue, setSearchValue] = useState("");


  if (error) {
    console.error('Error fetching data:', error);
  }
  if (searchError) {
    console.error('Error fetching data:', searchError);
  }
  if (catError) {
    console.error('Error fetching data:', catError);
  }

  const handleLoadMore = () => {
    if (totalShown < items.length) {
      setTotalShown(prev => prev + 6);
    }
  };

  useEffect(() => {
    if (currentPage !== object?.slugs[0][0]) {
      setItems([]);
      setCurrentPage(object?.slugs[0][0]);
    }
  }, [match])


  useEffect(() => {
    if (search === null) {
      if (!loading) {
        if (object?.entrySelect?.length) {
          if (!object.exclude) {
            setItems(filter(object.entrySelect));
          }
          else {
            setItems(filter(data?.data.filter(item => object?.entrySelect.every((item1) => item.title !== item1.title))));
          }
        }
        else {
          setItems(filter(data?.data));
        }

        if (filters === null) {
          setTotalShown(6);
        }
        else {
          if (Object.values(filters).every(item => item.length === 0)) {
            setTotalShown(6);
          }
        }
      }
    }
    else {
      if (!searchLoading) {
        setItems(filter(searchData?.data ? searchData.data.filter(item => !Array.isArray(item)) : []));
      }
    }
  }, [loading, data, error, searchData, searchLoading, filters]);



  const filter = (data) => {
    const filterdData = data?.filter((i) => {
      const itemCategories = i.categories ? Object.values(i.categories).map((cat) => cat.slug) : [];
      if (filters) {
        let found = false;
        let tempFilters = [];
        Object.values(filters).forEach(item => {
          if (item) {
            tempFilters = [...tempFilters, item]
          }
        });
        // itemCategories.forEach(cat => {
        //   if(filters.includes(cat)){
        //     found = true;
        //   };
        // });
        found = tempFilters.every(filter => {
          if (filter.length === 0) {
            return true;
          }
          return filter.some(item => itemCategories.includes(item));
        })
        return found;
      }
      return true;
    })
    if (object?.slugs[0][0] === 'events') {
      return filterdData.sort((a, b) => new Date(a.expiryDate) - new Date(b.expiryDate))
    }
    if (object?.slugs[0][0] === 'courses') {
      return filterdData.sort((a, b) => {
        if (a.title < b.title) { return -1; }
        if (a.title > b.title) { return 1; }
        return 0;
      })
    }
    return filterdData;
  }
  return (
    <Widget className="itemlist">
      <div className="inner">
        {search &&
          <form className={`searchbar ${search !== 'search' ? 'active' : ''}`}>
            <input
              className="searchinput"
              value={searchValue}
              required
              minLength="3"
              placeholder="Search..."
              type="search"
              name="q"
              onChange={(e) => setSearchValue(e.target.value)}
              />
            <button className="button full darken">Search</button>
          </form>
        }
        {(search && searchLoading) && <div className="loader"></div>}
        <div className={`top ${search === 'search' ? 'no-margin' : ''}`}>
          {object?.title && <h2>{object.title}</h2>}
          {object && ((object.categories && !Array.isArray(object.categories)) && <FilterComponent categories={object.categories} filters={filters} setFilters={setFilters} setShowFilters={setShowFilters} windowWidth={windowWidth} title={object.title} />)}
          {search && catData && ((catData?.data && !Array.isArray(Object.assign({}, catData?.data))) && <FilterComponent categories={Object.assign({}, catData?.data)} filters={filters} setFilters={setFilters} setShowFilters={setShowFilters} windowWidth={windowWidth} />)}
        </div>
        {object && ((windowWidth < 744 && showFilters) && <FilterComponent onlyWrapper={true} categories={object.categories} filters={filters} setFilters={setFilters} setShowFilters={setShowFilters} windowWidth={windowWidth} />)}
        {search && catData && ((windowWidth < 744 && showFilters) && <FilterComponent onlyWrapper={true} categories={Object.assign({}, catData?.data)} filters={filters} setFilters={setFilters} setShowFilters={setShowFilters} windowWidth={windowWidth} />)}
        {items.length !== 0 && <ul className="slides">
          {
            items.slice(0, totalShown).map((item, i) => {
              if (!search) {
                return (
                  <Card key={item.title + i} type={object.slugs[0][0]} title={item.title} description={item.maindescription} date={item.postdate} expiryDate={item.expiryDate} categories={item.categories} uri={item.uri} imageURL={item.images?.s} imageFocusX={item.images?.focus.x} imageFocusY={item.images?.focus.y} alt={item.title} emphasisLine={item.emphasisLine} />
                )
              }
              return (
                <Card key={item.title + i} type='search' title={item.title} description={item.description} date={item.postdate} uri={item.uri} imageURL={item.image?.m} imageFocusX={item.image?.focus.x} imageFocusY={item.image?.focus.y} alt={item.title} categories={item.categories} section={item.section} site={item.site} />
              );
            })}
        </ul>}
        {totalShown < items.length && <button onClick={handleLoadMore}>Load More</button>}
      </div>
    </Widget>
  );
};

export default Itemlist;
