import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query';
const endpoint = process.env.REACT_APP_CRAFT_URL+"/graphql/api";
const headers = {
  "content-type": "application/json",
  'Cache-Control': 'no-cache',
  'X-Requested-With': 'XMLHttpRequest',
  'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
  'X-Craft-Solspace-Freeform-Mode': 'Headless',
};
export const runQuery = async (query, variables) => {
  const graphqlQuery = {
    "query": `${query}`,
    "variables": variables
};

const options = {
  "method": "POST",
  "headers": headers,
  "body": JSON.stringify(graphqlQuery)
};
  try {
    const response = await fetch(endpoint, options);
    return response.json();
  }
  catch (e) {
    console.error(e);
  }

}

export const buildQuery = (formProperties,formHandle,fieldTypes,reCaptcha) => {
  let variables = {};
  let args = {};
  if(reCaptcha){
    variables['reCaptcha'] = "FreeformSubmissionReCaptchaInputType";
    args['reCaptcha'] = new VariableType("reCaptcha");
  }
  if(formProperties){
    Object.entries(formProperties).forEach(([k,v]) => {
      if(Array.isArray(v)){
        if(fieldTypes.hasOwnProperty(k) && fieldTypes[k] === 'file'){
          variables[k] = '[FreeformFileUploadInputType]';
        }
        else{
          variables[k] = "[String]";
        }
      }
      else if(typeof v == 'number'){
        variables[k] = "Int"
      }
      else{
        variables[k] = "String";
      }
      args[k]= new VariableType(k);
    })
  }
  const query = {
    mutation:{
      __variables:{
        honeypot:"FreeformHoneypotInputType",
        csrfToken:"FreeformCsrfTokenInputType",
        ...variables
      },
      [`save_${formHandle}_Submission`]:{
        __args:{
          honeypot: new VariableType("honeypot"),
          csrfToken:new VariableType("csrfToken"),
          ...args
        },
        submissionId:true,
        success:true
      }
    }
  }
  const graphql_query = jsonToGraphQLQuery(query, { pretty: true });
  return graphql_query;
}