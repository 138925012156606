import React, { useEffect, useRef } from 'react'

const Checkbox = ({ attributes,label,size,handleDefault = null ,triggers,formData}) => {
    const ref = useRef(null);

    useEffect(() => {
        if(attributes.rowid && attributes.hidden){
            document.getElementById(attributes.rowid).classList.add('no-margin');
        }
        if(ref && handleDefault && formData[attributes.name] === undefined){
            handleDefault(ref.current,triggers);
        }
    },[ref])
    return (
        <div className={`checkbox-label ${attributes.hidden ? 'hidden' : ''}`} htmlFor={attributes.name}>
            <div className='field-checkbox-wrapper' style={{minWidth:size,minHeight:size}}>
                <span>
                  <input ref={ref} className={`form-input field-input-checkbox ${attributes.form_page}`} type={`${attributes.type ? "radio" : "checkbox"}`} {...attributes}  style={{minWidth:size,minHeight:size}}/>
                  <span className='checkbox-filling'></span>
                </span>
            </div>
            {label}
        </div>
    )
}

export default Checkbox