import React, { useMemo} from 'react';
import { Widget } from '../components/Widget';
import { LinkReplace } from '../components/LinkReplace';


const CTA = ({ data }) => {
  const fields = useMemo(() => {
    let tempData = { ...data };
    tempData.ctas.forEach(item => {
      let blocks = {}
      if(Array.isArray(item.cta.blocks)){
        item.cta.blocks.forEach(block => {
          blocks = { ...blocks, [block.type]: block }
        })
        item.cta.blocks = blocks;
      }
    });
    return tempData
  }, [data])
  return (
    <Widget className="cta">
      {(fields.ctas && fields.ctas.length) && fields.ctas.map(({cta:{title,style,blocks,textPosition}},index) => {
        return (
          <div key={`cta${index}`} className={`inner ${style ? style?.style : ""} ${textPosition ? 'text-' + textPosition.textPosition : ""} ${blocks.buttons.buttons.length && !data.pattern ? 'site-' + blocks.buttons.buttons[0].type  : ''}`} style={ blocks.image && style.style !== "single" ? { backgroundImage: `url(${blocks.image?.image})`, backgroundPosition: `${blocks.image?.focus?.x * 100}% ${ blocks.image?.focus?.y * 100 }%`, } : {}}>
            <h2>{title}</h2>
            {blocks.description?.description && <p>
              {blocks.description?.description}
            </p>}
            {(blocks.buttons && blocks.buttons.buttons.length) && blocks.buttons.buttons.map((item, index) => {
              return (
                <LinkReplace 
                key={item.title + index} 
                to={item.type === 'external' ? item.url : item.uri} 
                site={item.type} 
                className={`button ${style?.style === 'single' ? 'darken' : ''} ${item.style}`}
                external={item.type === 'external' ? true : false}
                >{item.title}</LinkReplace>
              )
            })}

          </div>
        )
      })}
    </Widget>
  );
}

export default CTA;