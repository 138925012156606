import React from 'react';
import { Widget } from '../components/Widget';
import { LinkReplace } from '../components/LinkReplace';

const Programme = ({ data }) => {
  return (
    <Widget className="programme">
      <div className="inner">
        <ul>
          {
            data.programmes.map((item, index) => {
              const open = item.toggle;
              return (
                <li ani="y"  key={item.title+index} className={open ? 'open' : 'closed'}>
                  <div className="inner">
                    <LinkReplace to={item.uri} className="clicker">{item.title}</LinkReplace>
                    <h3>{item.title}</h3>
                    <LinkReplace to={item.uri} className="button outline">{open ? 'Learn more' : 'Coming soon'}</LinkReplace>
  
                    { open && <div className="marquee">
                      <div><div>
                        <p>{item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee}</p>
                        <p>{item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee}</p>
                        <p>{item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee}</p>
                        <p>{item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee} {item.hoverMarquee}</p>
                      </div></div>
                    </div>}
                  </div>
                </li>
              )
            })
          }
        </ul>
      </div>
    </Widget>
  );
}

export default Programme;