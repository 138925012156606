import React, { useMemo, useState, useEffect } from 'react';
import { Widget } from '../components/Widget';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import { sanitizedData } from '../utils/sanitize';
import { useSwiper } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/autoplay';
import { LinkReplace } from '../components/LinkReplace';

function SwiperButtons() {
  const swiper = useSwiper();
  return (
    <div className="controls">
      <button className="arrow left" onClick={() => swiper.slidePrev()}>L</button>
      <button className="arrow right" onClick={() => swiper.slideNext()}>R</button>
    </div>
  )
}

const Slides = ({ data }) => {
  const [spaceBetween, setSpaceBetween] = useState(window.innerWidth < 743 ? 10 : 100);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 743) {
        setSpaceBetween(10);
      } else {
        setSpaceBetween(100);
      }
    }

    window.addEventListener('resize', handleResize);

    // Initial call to handle current size
    handleResize();

    // Cleanup the event listener when the component unmounts
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const slides = useMemo(() => {
    let items = data.slides ?? [];
    if(items.length > 0){
      while (items.length < 5) {
        items = [...items, ...data.slides];
      }
    }
    return items;
  }, [data]);

  return (
    <Widget className="slides nobg">
      {slides && <div className='inner'>
        <Swiper
          slidesPerView='auto'
          spaceBetween={spaceBetween}
          loop={true}
          centeredSlides={true}
          modules={[Autoplay]}
          autoplay={
            {
              delay: 3000,
              pauseOnMouseEnter: true
            }
          }
        >
          {
            slides.map((item, index) => {
              return (
                <SwiperSlide key={item.title + index}>
                  <div className={`item site-${item.blocks[0]?.buttons[0]?.type}`}>
                    <div className="text">
                      <div className="innertext">
                        <h3>{item.title}</h3>
                        <p dangerouslySetInnerHTML={sanitizedData(item.mainDescription)} />
                      </div> 
                      
                      <div className="buttongroup">
                      {item.blocks[0].buttons.map((item, index) => {
                        return (
                          <LinkReplace
                            key={item.title + index}
                            to={item.type === 'external' ? item.url : item.uri}
                            site={item.type}
                            className={`button ${item.style} darken bottom`}
                            external={item.type === 'external' ? true : false}
                          >{item.title}</LinkReplace>
                        )
                      })}
                      </div>
                    </div>

                    <img 
                      loading="lazy"
                      src={item.image.url} 
                      alt="slide-image" 
                      style={{objectPosition: `${item.image.focus?.x * 100}% ${item.image.focus?.y * 100}%` }}
                    />
                    <div className={`pattern`}></div>
                  </div>
                </SwiperSlide>
              )
            })
          }
          <SwiperButtons />
        </Swiper>
      </div>}
    </Widget>
  );
}

export default Slides;