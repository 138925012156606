import React from 'react';
import { Widget } from '../components/Widget';
import { LinkReplace } from '../components/LinkReplace';

const DataList = ({ data }) => (
  <Widget className="datalist">
    <div className='inner'>
      <h3>{data.mainTitle}</h3>
      <ul>
        {
          data.datalist.map((item, index) => {
            return (
              <li key={item.title+index}>
                <p>
                <LinkReplace
                  to={item.destination}
                  site={item.type}
                  external={item.type === 'external'}
                  >
                    <strong>{item.title}</strong>
                    {item.description}
                  </LinkReplace>
                </p>
              </li>
            )
          })
        }
      </ul>
    </div>
  </Widget>
);

export default DataList;