export const showHideConditional = (criteria,rules,hiddenFields,setHiddenFields) => {
  var tempHiddenFields = [...hiddenFields];
    for (const key in criteria) {
        let show = [];
        const matchAll = rules[key]?.matchAll
        const hide = !rules[key]?.show
        const chosenAnswers = criteria[key];
        const defaultAnswers = rules[key].criteria;

        defaultAnswers.forEach(item => {
            show.push(item.value === chosenAnswers[item.hash]);
        })
        // const field = document.getElementById(`condition${key}`);
        const showAll = matchAll ? show.every(value => value === true) : show.includes(true);
        // field?.classList.toggle('hidden',  hide ? !showAll : showAll);
        if(hide){
          if(showAll){
            tempHiddenFields.push(key);
          }
          else{
            tempHiddenFields = tempHiddenFields.filter(item => item !== key);
          }
        }
        else{
          if(showAll){
            tempHiddenFields =  tempHiddenFields.filter(item => item !== key);
          }
          else{
            tempHiddenFields.push(key);
          }
        }
    }
    setHiddenFields(tempHiddenFields);
}


export const getRules = (layoutJson) => {
    const dataObj = {};
    const rulesList = layoutJson.composer.properties.rules.list;
  
    if (!Array.isArray(rulesList)) {
      for (const [key, value] of Object.entries(rulesList)) {
        const fieldRules = value.fieldRules;
        for (const item of fieldRules) {
          dataObj[item.hash] = item;
          for (const item1 of item.criteria) {
            item1.trigger = item.hash;
            dataObj[item1.hash] ??= [];
            dataObj[item1.hash].push(item1);
          }
        }
      }
    }
  
    return dataObj;
  };
  