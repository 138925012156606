import {useEffect,useRef} from 'react'

const Input = ({ attributes,handleSubmit = null,loading = null,loadingText = null,btn = null ,handleDefault=null,hide,formData}) => {
  const ref = useRef(null);

  useEffect(() => {
      if(ref && handleDefault && formData[attributes.name] === undefined){
          handleDefault(ref.current);
      }
  },[ref])

  return (
    <div className={`field-wrapper ${hide ? 'hidden':''}`} id={`condition${attributes.column}`}>
      <div className='input-wrapper'>
        <input ref={ref} className={`form-input field-input ${attributes.form_page} ${btn ? 'hasBtn' : ''}`}  {...attributes} placeholder={`${attributes.placeholder}${attributes.required ? '*' : ''}`} />
        {btn && <button type='button' className='button full' onClick={handleSubmit}>{loading? loadingText : btn.value}</button>}
      </div>
      <label className={`field-${attributes.name}-error form-field-error`}></label>
    </div>
  )
}

export default Input