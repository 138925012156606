import React from 'react';
import { sanitizedData } from '../utils/sanitize';
import { AccordionComponent, AccordionItem, useAccordion } from '../components/AccordionComponent';
import { Widget } from '../components/Widget';

const Accordion = ({ data,multiple = false }) => {
  const accordionHook = useAccordion(data.accordion.length);


  return (
    <Widget className="accordion">
      <div className='inner'>
        <AccordionComponent title={data.title}>
          {
            data.accordion.map((item,index) => {
              const doc = new DOMParser().parseFromString(sanitizedData(item.description).__html.replace(/\n[ ]*/g, ""),'text/html');
              const tables = Array.from(doc.querySelectorAll('table'));
              tables.forEach(table => {
                const wrapper = doc.createElement('div');
                wrapper.className = 'table';
                table.parentNode.insertBefore(wrapper, table);
                wrapper.appendChild(table);
              });
              const htmlString = new XMLSerializer().serializeToString(doc).replace(/<(body|head|html)\b[^>]*>|<\/(body|head|html)>/gi, "");
              return(
                <AccordionItem key={`AccordionItem${index}`}
                index={index}
                accordionHook={accordionHook}
                multiple={multiple}
                head={
                  <h3>{item.title}</h3>
                }
                body={
                  <div className='accordion-content' dangerouslySetInnerHTML={{__html:htmlString}} />
                }
                />
              )
            })
          }
        </AccordionComponent>
      </div>
    </Widget>
  )
};

export default Accordion;