import React, { useRef, useState } from 'react'
import Checkbox from '../components/Checkbox';

export const FilterButton = ({ title, slug, categories, filters, setFilters, hide, hover = true, onClick }) => {
    const ref = useRef(null);
    return (
        <div className={`filter-button btn-wrapper arrowup`} ref={ref} style={{ display: hide ? 'none' : 'block' }} onClick={hover ? (e) => { e.target.classList.toggle("open") } : (e) => {
            if (onClick) {
                onClick();
            }
            if (!hover) {
                    ref.current.classList.toggle('open-mobile')
            }
        }}>
            <span>{title}</span>
            {categories && <div className='radios-wrapper' >
                <div className='radios'>
                    {categories?.map((item, index) => {
                        return (
                            <Checkbox key={item + index}
                                attributes={
                                    {
                                        name: slug,
                                        id: item.slug,
                                        defaultValue: item.slug,
                                        onChange: (event) => {
                                            let tempFilters = filters ? { ...filters } : {};
                                            if (tempFilters[slug]) {
                                                if(tempFilters[slug].includes(event.target.value)){
                                                    tempFilters[slug] = tempFilters[slug].filter((item) => item !== event.target.value);
                                                }
                                                else{
                                                    tempFilters[slug].push(event.target.value);
                                                }
                                                setFilters(() => tempFilters);
                                                return;
                                            }
                                            tempFilters[slug] = [event.target.value];
                                            setFilters(() => tempFilters);
                                        },
                                        checked: filters && filters[slug]?.includes(item.slug)
                                    }
                                }
                                size={30}
                                label={item.title} />
                        )
                    })}
                </div>
            </div>}
        </div>
    )
}
