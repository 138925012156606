import React, { useState, useCallback, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SiteContext } from './SiteContext';
import GetData from '../utils/api';
import { LinkReplace } from '../components/LinkReplace';

function Navigation() {
  const { selectedSite } = useContext(SiteContext);
  const location = useLocation();
  const siteValue = (selectedSite === "global" || selectedSite === '') ? '' : '/' + selectedSite;
  const home = siteValue + '/home';
  const { data: logo } = GetData(`${process.env.REACT_APP_CRAFT_URL}${siteValue}/api/get/header.json`);
  const { data, error } = GetData(`${process.env.REACT_APP_CRAFT_URL}${siteValue}/api/get/navigation.json`);
  // const [searchValue,setSearchValue] = useState("");

  if (error) {
    console.error('Error fetching menu data:', error);
  }

  const isActive = (menuUri) => {
    const currentPath = location.pathname;
    const normalizedCurrentPath = currentPath === '/' ? '/home' : currentPath;
    const normalizedMenuUri = menuUri === '__home__' ? '/home' : `/${menuUri}`;
    return normalizedCurrentPath === normalizedMenuUri;
  };

  const getMenuUri = (menuUri) => {
    return menuUri === '__home__' ? '/home' : `${siteValue}/${menuUri}`;
  };
  
  const [isOpen, setIsOpen] = useState(false);
  const toggleOpen = useCallback(() => {
    setIsOpen((prev) => !prev);
  }, []);

  const closeMenu = useCallback(() => {
    setIsOpen(false);
  }, []);

  const [openCardIndex, setOpenCardIndex] = useState(-1);

  const toggleCard = (i) => {
    setOpenCardIndex(i === openCardIndex ? -1 : i);
  };

  return (
    <header className={isOpen ? 'open' : ''}>
      <div className="inner">
        <Link to={home}>
          <img className="logo" src={logo?.logo} alt={logo?.sitename} />
          <h2 className="sitename">{logo?.sitename}</h2>
        </Link>
        <nav>
            <a className="search smobile" href="?q=search">Search</a>

          <div className="hamburger" onClick={toggleOpen}>
            =
          </div>
          <ul>
            {data?.data?.map((item, i) => {
              const isCardOpen = i === openCardIndex;
              return (
                <li
                  key={i}
                  className={`${isCardOpen ? 'open' : ''}`}
                  onClick={() => toggleCard(i)}
                >
                  <LinkReplace
                    to={item.type === 'CustomType' ? item.url : item.uri}
                    external={item.type === 'CustomType' ? true : false}
                    className={isActive(item.uri) ? 'active' : ''}
                    site={''}
                  >
                    <span onClick={closeMenu}>{item.title}</span>
                  </LinkReplace>
                  {item.children && (
                    <div className='submenu'>
                      <div className='wrapper'>
                        {item.children.map((level1, index1) => {
                          return (
                            <ul key={level1.title + index1}>
                              <li>
                                <LinkReplace
                                  to={level1.type === 'CustomType' ? level1.url : level1.uri}
                                  external={level1.type === 'CustomType' ? true : false}
                                  site=''
                                >
                                  <span onClick={closeMenu}>{level1.title}</span>
                                </LinkReplace>
                              </li>
                              {level1.children && level1.children.map((level2, index2) => {
                                return (
                                  <li key={level2.title + index2}>
                                    <LinkReplace
                                      to={level2.type === 'CustomType' ? level2.url : level2.uri}
                                      external={level2.type === 'CustomType' ? true : false}
                                      site=''
                                    >
                                      <span onClick={closeMenu}>{level2.title}</span>
                                    </LinkReplace>
                                  </li>
                                )
                              })}
                            </ul>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </li>
              )
            })}
          </ul>

          <a className="search sdesktop" href='?q=search'>Search</a>

          {/* <form className="searchbar">
            <input
              className="searchinput"
              value={searchValue}
              required
              minLength="3"
              placeholder="search"
              type="search"
              name="q"
              onChange={(e) => setSearchValue(e.target.value)}
            />
            <button className="button full darken">Search</button>
          </form> */}

        </nav>
      </div>
    </header>
  );
}

export default Navigation;