import React from 'react';
import { sanitizedData } from '../utils/sanitize';
import { Widget } from '../components/Widget';

const Multitest = ({ data }) => (
  <Widget className="multitest">
    <div className="inner">
      {data.headimage.map((image, i) => (
        <figure key={i}>
          <img loading='lazy' src={image} alt={i} />
        </figure>
      ))}
    
      <h1>{data.maintitle}</h1>
      <h2>{data.subtitle}</h2>
      <div dangerouslySetInnerHTML={sanitizedData(data.paraaf)} />
    </div>
  </Widget>
);

export default Multitest;