import React, { useState, useEffect } from 'react';

const Cursor = () => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovering, setIsHovering] = useState(false);
  const [isMouseDown, setIsMouseDown] = useState(false); 

  useEffect(() => {
    const handleMouseMove = (e) => {
      setPosition({ x: e.clientX, y: e.clientY });

      const targetTag = e.target.tagName.toLowerCase();
      if (targetTag === 'a' || targetTag === 'button' || targetTag === 'input' || e.target.classList.contains('checkbox-label') || e.target.classList.contains('removeFile')) {
        setIsHovering(true);
      } else {
        setIsHovering(false);
      }
    };

    const handleMouseDown = () => {
      setIsMouseDown(true);
    };

    const handleMouseUp = () => {
      setIsMouseDown(false);
    };

    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('mousedown', handleMouseDown);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mousedown', handleMouseDown);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, []);

  return (
    <div className={`cursor ${isHovering ? 'hover' : ''} ${isMouseDown ? 'mousedown' : ''}`}
      style={{
        top: position.y + 'px',
        left: position.x + 'px',
      }}
    ></div>
  );
}

export default Cursor;