import React, { useState } from 'react'
import { Widget } from '../components/Widget';
import FormComponent from '../components/FormComponent';
import { sanitizedData } from '../utils/sanitize';
import { LinkReplace } from '../components/LinkReplace';
import GetData from '../utils/api';

export const DetailCard = ({ data }) => {
    const [showForm, setShowForm] = useState();
    const { data:formData } = GetData((process.env.NODE_ENV !== 'development' && (showForm && data.type === 'EventDetailCards' && data.detailCard.form)) && `${process.env.REACT_APP_CRAFT_URL}/freeform/form/properties/${data.detailCard?.form[0].form?.id}`);
    return (
        <Widget className='detailcard'>
            <div className='inner'>
                <div className='left'>
                    {data.detailCard?.detailBody && <div className='text'>
                    <h3>{data.detailCard.detailBody[0]?.title}</h3>
                    <p dangerouslySetInnerHTML={sanitizedData(data.detailCard.detailBody[0]?.description)}/>
                    </div>}
                </div>
                <div className='right'>
                    {!showForm && <div className='body'>
                        <ul className='list'>
                            {
                                data.detailCard?.listItem?.map((item, index) => {
                                    return (
                                        <li key={item.listItemTitle+index}>
                                            <h4>{item.listItemTitle}</h4>
                                            <p dangerouslySetInnerHTML={sanitizedData(item.listItemDescription)} />
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        {(data.type === 'detailCards' && data) && <>
                        {
                            data.detailCard?.tags && 
                            <div className='tags'>
                            <h4>{data.detailCard?.tags[0]?.tagTitle}</h4>
                            <ul>
                                {
                                    data.detailCard?.tags[0]?.tags?.map((item,index) => {
                                        return (
                                            <li key={item.title + index}><LinkReplace to={item.slug} disabled={true}>{item.title}</LinkReplace></li>
                                        )
                                    })
                                }
                            </ul>
                        </div>
                        }
                        </>}
                        {(!showForm && data.type === 'EventDetailCards' && data?.detailCard?.button) && (!data.detailCard?.button[0].external ? <button className={`button ${data.detailCard.button[0]?.style}`} onClick={() => { if(data?.detailCard?.form){setShowForm(true)} }}>{data.detailCard?.button[0]?.buttonTitle}</button> : <a target='_blank' className={`button ${data.detailCard.button[0]?.style}`} href={data.detailCard.button[0]?.buttonUrl}>{data.detailCard.button[0]?.buttonTitle}</a>)}
                    </div>}
                    {(showForm && data.type === 'EventDetailCards' && data.detailCard.form) &&
                        <div className='form'>
                            <FormComponent freeform={data.detailCard?.form[0]?.freeform} complete={data.detailCard?.form[0]?.complete} formProperties={formData} />
                            <a className='back'  onClick={() => {setShowForm(false)}}>Return to event details</a>
                        </div>
                    }
                </div>
            </div>
        </Widget>
    )
}
