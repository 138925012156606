// SiteContext.js
import React, { createContext, useState, useEffect } from 'react';

const SiteContext = createContext();

const SiteProvider = ({ children }) => {
  const route = () => {
    var pathArray = window.location.pathname.split('/');
  if (pathArray[1] === "connect" || pathArray[1] === "accelerate" || pathArray[1] === "thrive") {
    document.body.setAttribute('site', pathArray[1]);
    return pathArray[1];
  } else {
    document.body.setAttribute('site', "global");
    return'global';
  }
  }
  const [selectedSite, setSelectedSite] = useState(route());

  // when user interacts with browser history
  const handlePopState = () => {
    setSelectedSite(route());
  }
  useEffect(() => {

    window.addEventListener('popstate', handlePopState);
    return () => {
      window.addEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <SiteContext.Provider value={{ selectedSite, setSelectedSite }}>
      {children}
    </SiteContext.Provider>
  );
};

export { SiteContext, SiteProvider };