import React from 'react'
import GetData from '../utils/api';
import HeadSplit from './HeadSplit';
import { Helmet } from 'react-helmet-async';

export const Error = () => {
    const { data,loading } = GetData(`${process.env.REACT_APP_CRAFT_URL}/api/get/uri/error.json`);
    if (loading) {
        return (
          <div className={`webbox ${loading ? 'fade-out' : ''}`}>
            <div className="loader"></div>
          </div>
        );
      }
  return (
    <>
    <Helmet>
    <title>{data.maintitle || data.title || 'Error' }</title>
    </Helmet>
    <HeadSplit data={data.header} bread={data.breadcrumbs} />
    </>
  )
}
