import React, { useRef, useEffect } from 'react'

export const Widget = ({ className, children }) => {
  const ref = useRef(null);

  function handleScroll() {
      if(ref.current){
        var windowHeight = window.innerHeight;
      var elementTop = ref.current.getBoundingClientRect().top;
      var elementBottom = ref.current.getBoundingClientRect().bottom;
      var elementVisible = 150;
      if ((elementTop < windowHeight - elementVisible) && elementBottom > 0) {
        ref.current.classList.add("start");
      } else {
        if (ref.current.classList.contains("start")) {
          ref.current.classList.remove("start");
        }
      }
      }
  }

  useEffect(() => {
    if(ref){
      handleScroll();
    }
    window.addEventListener("scroll", handleScroll);
    return window.addEventListener("scroll", handleScroll);
  }, [])

  useEffect(() => {
    if(ref){
      handleScroll();
    }
  }, [className])

  return (
    <section ref={ref} className={`widget ${className}`}>
      {children}
    </section>
  )
}
