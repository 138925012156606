import { useState,useEffect,useMemo } from "react"
export default function useInViewPort(ref) {
  const [isVisible, setVisible] = useState(false)

    const observer = useMemo(() => new IntersectionObserver(
      ([entry]) => setVisible(entry.isIntersecting)
    ))
  
  
    useEffect(() => {
      observer.observe(ref.current)
      return () => observer.disconnect()
    }, [])

    return isVisible;
  }