import React from 'react';
import { sanitizedData } from '../utils/sanitize';

const Contenttext = ({ data }) => (
  <div className="widget contenttext">
    <div className="inner">
      {data.contenttext.map((content, i) => {
        if (content.type === 'maintitle') {
          return <h2 key={i}>{content.inner}</h2>;
        } else if (content.type === 'subtitle') {
          return <h3 key={i}>{content.inner}</h3>;
        } else if (content.type === 'text') {
          return <p key={i} dangerouslySetInnerHTML={sanitizedData(content.inner)} />;
        } else if (content.type === 'image') {
          return <img loading='lazy' width="100%" src={content.inner} alt={i} />;
        }
        return null; 
      })}
    </div>
  </div>
);

export default Contenttext;