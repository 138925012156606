import React, { useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

function Breadcrumb({ bread }) {
    const [siteName, setSiteName] = useState('Home'); // Default to 'Home'

    useEffect(() => {
        // Get the value of the 'site' attribute from the <body> element
        const siteAttributeValue = document.body.getAttribute('site');
        if (siteAttributeValue) {
            setSiteName(siteAttributeValue);
        }
    }, []);

    // Determine the URL for the "Home" link based on siteName
    const homeLinkTo = siteName === 'global' ? '/home' : `/${siteName}/home`;

    return (
        <div className="breadcrumb">
            <Link to={homeLinkTo}>{siteName}</Link>
            {bread.map((data, index) => {
                return (
                    <React.Fragment key={index}>
                        <span>/</span>
                        <Link to={data.uri}>{data.title}</Link>
                    </React.Fragment>
                );
            })}
        </div>
    );
}

export default Breadcrumb;