import React from 'react'
import { FilterButton } from './FilterButton';

export const FilterComponent = ({ categories, filters, setFilters, onlyWrapper,setShowFilters,windowWidth,title }) => {
    if (onlyWrapper) {
        return (
            <div className='filters-wrapper mobile'>
                {
                    Object.values(categories).map((item, index) => {
                        if (item.children.length > 0) {
                            return (
                                <FilterButton key={item.slug + index} title={item.title} slug={item.slug} categories={item.children} filters={filters} hover={false} setFilters={setFilters} />
                            )
                        }
                    })
                }

            </div>
        )
    }
    return (
        <div className='filter-component filters'>
            {windowWidth < 744  && <a onClick={() => setFilters(null)} className='clear'>Clear all filters</a>}
            {
                windowWidth < 744 ?

                    <FilterButton title='Filters' hover={false} onClick={() => setShowFilters((prev) => !prev)} />
                    :
                    <div className={`filters-wrapper ${title ? 'title' : ''}`}>
                        <a onClick={() => setFilters(null)} className='clear'>Clear all filters</a>
                        {
                            Object.values(categories).map((item, index) => {
                                if (item.children.length > 0) {
                                    return (
                                        <FilterButton key={item.slug + index} title={item.title} slug={item.slug} categories={item.children} filters={filters} setFilters={setFilters} />
                                    )
                                }
                            })
                        }
                    </div>
            }
        </div>
    )
}
