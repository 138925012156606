import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom';
import { SiteProvider } from './widgets/SiteContext';
import TagManager from 'react-gtm-module'

// CSS
import './styles/styles.scss';

// Main
import Siteswitch from './widgets/Siteswitch';
import Navigation from './widgets/Navigation';
import FullPattern from './widgets/FullPattern';
import Footer from './widgets/Footer';
import Page from './widgets/Page';
import Cursor from './widgets/Cursor';


const tagManagerArgs = {
  gtmId: 'GTM-NW7K66HN'
}
TagManager.initialize(tagManagerArgs)


const App = () => {
  window.dataLayer.push({
    event: 'pageview'
  });
  const [redirectToHome, setRedirectToHome] = useState(false);
  const [home, setHome] = useState(false);
  const thisUrl = window.location.pathname;
  useEffect(() => {

    if (thisUrl === '/') {
      setHome('/home');
      setRedirectToHome(true);
    }
    if (thisUrl === '/connect' || thisUrl === '/connect/') {
      setHome('/connect/home');
      setRedirectToHome(true);
    }
    if (thisUrl === '/thrive' || thisUrl === '/thrive/') {
      setHome('/thrive/home');
      setRedirectToHome(true);
    }
    if (thisUrl === '/accelerate' || thisUrl === '/accelerate/') {
      setHome('/accelerate/home');
      setRedirectToHome(true);
    }
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const isScrolling = window.scrollY > 0;
      document.body.classList.toggle('scrolling', isScrolling);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
      const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
      if (isTouchDevice) {
        document.body.classList.add('touchdevice');
      } 
    }, []);

  return (
    <Router>
      <SiteProvider>
        <Cursor />
        <Siteswitch />
        <Navigation />
        {redirectToHome && <Redirect to={home} />}
        <Route path="/:pageId+" component={({ match }) => <Page match={match} />} />
        <FullPattern />
        <Footer />
      </SiteProvider>
    </Router>
  );
};

export default App;