// Widgets
import Maintitle from './Maintitle';
import Subtitle from './Subtitle';
import Paraaf from './Paraaf';
import Imagebox from './Imagebox';
import Multitest from './Multitest';
import Imageslider from './Imageslider';
import Itemlist from './Itemlist';
import Contenttext from './Contenttext';
import HeadSplit from './HeadSplit';
import Video from './Video';
import CTACards from './CTACards';
import Slides from './Slides';
import Newsslider from './Newsslider';
import Form from './Form';
import CTA from './CTA';
import FormNormal from './FormNormal';
import Quotes from './Quotes';
import Article from './Article';
import Newsletter from './Newsletter';
import Accordion from './Accordion';
import SplitText from './SplitText';
import ImageSlider from './ImageSlider2';
import DataList from './DataList';
import ScheduleList from './ScheduleList';
import TeamList from './TeamList';
import Programme from './Programme';
import GetData  from '../utils/api';
import { useContext } from 'react';
import { SiteContext } from './SiteContext';
import { LogoSlider } from './LogoSlider';
import { DetailCard } from './DetailCard';
import { Helmet } from 'react-helmet-async';
import {  Redirect } from 'react-router-dom';
import { Error } from './Error';
import { Gtag } from './Gtag';



const Page = ({ match }) => {
  const queryParams = new URLSearchParams(window.location.search);
  const { selectedSite } = useContext(SiteContext);
  const pageId = match.params.pageId.replace(/(global\/|connect\/|accelerate\/|thrive\/)/, '');
    const pageId2 = pageId.replace(/\/(global\/|connect\/|accelerate\/|thrive\/)/, '');
    let pageIdParts = pageId2.split('/');
    if(pageIdParts[0] === '') {
      pageIdParts.shift();
    }
    const siteValue = (selectedSite==="global" || selectedSite==='') ? '' : '/' + selectedSite;
    const {data,loading,error} = GetData((queryParams.get('q') === null ) && `${process.env.REACT_APP_CRAFT_URL}${siteValue}/api/get/uri/${pageIdParts.join('/')}.json`);
  if(data?.error){
    console.error('Error fetching data:', data.error);
  }
  if (loading) {
    return (
      <div className={`webbox ${loading ? 'fade-out' : ''}`}>
        <div className="loader"></div>
      </div>
    );
  }
  
  window.scrollTo(0, 0);
  
  let favicons;
  let websiteTitle;

  if (selectedSite === 'global') {
    websiteTitle = 'Scotch Global';
    favicons = { appleTouchIcon: '/assets/icons/global/apple-touch-icon.png', favicon32x32: '/assets/icons/global/favicon-32x32.png', favicon16x16: '/assets/icons/global/favicon-16x16.png', manifest: '/assets/icons/global/site.webmanifest', maskIcon: '/assets/icons/global/safari-pinned-tab.svg', shortcutIcon: '/assets/icons/global/favicon.ico', msTileColor: '#444f22', msApplicationConfig: '/assets/icons/global/browserconfig.xml', themeColor: '#444f22', };
  } else if (selectedSite === 'thrive') {
    websiteTitle = 'Scotch Global Thrive';
    favicons = { appleTouchIcon: '/assets/icons/thrive/apple-touch-icon.png', favicon32x32: '/assets/icons/thrive/favicon-32x32.png', favicon16x16: '/assets/icons/thrive/favicon-16x16.png', manifest: '/assets/icons/thrive/site.webmanifest', maskIcon: '/assets/icons/thrive/safari-pinned-tab.svg', shortcutIcon: '/assets/icons/thrive/favicon.ico', msTileColor: '#4d1825',msApplicationConfig: '/assets/icons/thrive/browserconfig.xml', themeColor: '#4d1825', };
  } else if (selectedSite === 'connect') {
    websiteTitle = 'Scotch Global Connect';
    favicons = { appleTouchIcon: '/assets/icons/connect/apple-touch-icon.png', favicon32x32: '/assets/icons/connect/favicon-32x32.png', favicon16x16: '/assets/icons/connect/favicon-16x16.png', manifest: '/assets/icons/connect/site.webmanifest', maskIcon: '/assets/icons/connect/safari-pinned-tab.svg', shortcutIcon: '/assets/icons/connect/favicon.ico', msTileColor: '#832818', msApplicationConfig: '/assets/icons/connect/browserconfig.xml', themeColor: '#832818', };
  } else if (selectedSite === 'accelerate') {
    websiteTitle = 'Scotch Global Accelerate';
    favicons = { appleTouchIcon: '/assets/icons/accelerate/apple-touch-icon.png', favicon32x32: '/assets/icons/accelerate/favicon-32x32.png', favicon16x16: '/assets/icons/accelerate/favicon-16x16.png', manifest: '/assets/icons/accelerate/site.webmanifest', maskIcon: '/assets/icons/accelerate/safari-pinned-tab.svg', shortcutIcon: '/assets/icons/accelerate/favicon.ico', msTileColor: '#1a464e', msApplicationConfig: '/assets/icons/accelerate/browserconfig.xml', themeColor: '#1a464e' };
  }

  return (
    <div className={`webbox ${loading ? 'fade-out' : 'fade-in'} ${queryParams.get('q') === null  ? '' : 'search-box'}`}>
      <Helmet>
        <title>{data ? data.maintitle || data.title || 'Error'    : `Search ${queryParams.get('q') !== null ? queryParams.get('q') : ''}`} | {websiteTitle}</title>
        <link rel="apple-touch-icon" sizes="180x180" href={favicons.appleTouchIcon} />
        <link rel="icon" type="image/png" sizes="32x32" href={favicons.favicon32x32} />
        <link rel="icon" type="image/png" sizes="16x16" href={favicons.favicon16x16} />
        <link rel="manifest" href={favicons.manifest} />
        <link rel="mask-icon" href={favicons.maskIcon} color={favicons.msTileColor} />
        <link rel="shortcut icon" href={favicons.shortcutIcon} />
        <meta name="msapplication-TileColor" content={favicons.msTileColor} />
        <meta name="msapplication-config" content={favicons.msApplicationConfig} />
        <meta name="theme-color" content={favicons.themeColor} />
      </Helmet>

      {queryParams.get('q') !== null && <Itemlist search={queryParams.get('q')}/>}    
      {(queryParams.get('q') === null && data?.header && data.header?.length!==0) && <HeadSplit data={data.header} bread={data.breadcrumbs} />}

      {queryParams.get('q') === null && data?.body && data.body.length > 0 ? (
        data?.body.map((object, i) => {
          const type = object.type;
          switch (type) {
            case 'maintitle':
              return <Maintitle key={i} data={object} />;
            case 'subtitle':
              return <Subtitle key={i} data={object} />;
            case 'paraaf':
              return <Paraaf key={i} data={object} />;
            case 'multitest':
              return <Multitest key={i} data={object} />;
            case 'imagebox':
              return <Imagebox key={i} data={object} />;
            case 'itemlist':
              return <Itemlist key={i} object={object}  match={match} />;
            case 'contenttext':
              return <Contenttext key={i} data={object} />;


            case 'imageslider':
              return <Imageslider key={i} data={object} />;
            case 'HeadSplit':
              return <HeadSplit key={i} data={object} />;
            case 'Video':
              return <Video key={i} data={object} />;
            case 'CTACards':
              return <CTACards key={i} data={object} />;
            case 'slides':
              return <Slides key={i} data={object} />;
            case 'Newsslider':
              return <Newsslider key={i} data={object} match={match} />;
            case 'Form':
              return <Form key={i} data={object} />;
            case 'FormNormal':
              return <FormNormal key={i} data={object} />;
            case 'CTA':
              return <CTA key={i} data={object} />;
            case 'Quotes':
              return <Quotes key={i} data={object} />;
            case 'Article':
              return <Article key={i} data={object} bread={data.breadcrumbs} />;
            case 'Newsletter':
              return <Newsletter key={i} data={object} />;
            case 'accordion':
              return <Accordion key={i} data={object} />;
            case 'SplitText':
              return <SplitText key={i} data={object} />;
            case 'ImageSlider':
              return <ImageSlider key={i} data={object} />;
            case 'datalist':
              return <DataList key={i} data={object} />;
            case 'ScheduleList':
              return <ScheduleList key={i} data={object} />;
            case 'TeamList':
              return <TeamList key={i} data={object} />;
            case 'programmes':
              return <Programme key={i} data={object} />;
            case 'logosliders':
              return <LogoSlider key={i} data={object}/>
            case 'detailCards':
              return <DetailCard key={i} data={object}/>
            case 'EventDetailCards':
              return <DetailCard key={i} data={object}/>
            case 'gtag':
              return <Gtag data={object}/>
            default:
              return null;
          }
        })
      ) : queryParams.get('q') !== null ? "" :
      <div className='webbox'>
        {/* <Redirect to={`${siteValue}/error`} /> */}
        <Error/>
      </div>
      }
    </div>
  );
};

export default Page;