import React, { useState } from 'react';
import { sanitizedData } from '../utils/sanitize';
import { Widget } from '../components/Widget';
import { LinkReplace } from '../components/LinkReplace';

const CTACards = ({ data }) => {
  const [openCardIndex, setOpenCardIndex] = useState(-1); 

  const toggleCard = (index) => {
    setOpenCardIndex(index === openCardIndex ? -1 : index); 
  };

  return (
    <Widget className="ctacards">
      <div className="inner">
        {data.title && <h2>{data.title}</h2>}
        <ul>
          {data.cards.map((item, index) => {
            const isCardOpen = index === openCardIndex;

            return (
              <li ani="y" key={'CTACard' + index}>
                <div className={`card ${isCardOpen ? 'open' : ''}`} onClick={() => toggleCard(index)}>
                  <img loading='lazy' src={item.icon} alt={item.maintitle} />
                  <h3>{item.maintitle}</h3>
  
                  {item.hoverTitle && (
                    <div className={`hover ${isCardOpen ? 'open' : ''}`}>
                      <div className="close" onClick={() => toggleCard(index)}></div>
                      <h4>{item.hoverTitle}</h4>
                      <p dangerouslySetInnerHTML={sanitizedData(item.description)} />
                      {item.buttonDestinationUrl && 
                        <LinkReplace
                          to={item.type === 'internal' ? item.buttonDestinationUri : item.buttonDestinationUrl}
                          className="button outline"
                          external={item.type === 'internal' ? false : true}
                        >
                          {item.buttonTitle}
                        </LinkReplace>
                      }
                    </div>
                  )}
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </Widget>
  );
};

export default CTACards;