import {useEffect,useRef} from 'react'

export const Select = ({field,attributes,options,handleDefault = null,triggers}) => {

  const ref = useRef(null);

  useEffect(() => {
      if(ref && handleDefault){
          handleDefault(ref.current,triggers);
      }
  },[ref])

  return (
    <div className="field-wrapper select-wrapper" id={`condition${attributes.column}`}>
    {field.label && <label className='form-label' htmlFor={field.handle}>{field.label}</label>}
    <select ref={ref} className={`form-select field-input ${attributes.form_page} ${attributes.multiple ? 'multiple' : 'single'}`} {...attributes} >
        {options.map((option,i) => {
            return <option key={`${attributes.name}Option${i}`} value={option.label} onMouseDown={attributes.multiple ? (e) => {
                e.preventDefault();
                e.target.selected = !e.target.selected;
                handleDefault(ref.current)
                return false;
            } : undefined}>{option.label}</option>;
        })}
    </select>
    <label className={`field-${attributes.name}-error form-field-error`}></label>
</div>
  )
}
