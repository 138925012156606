import React from 'react';
import { sanitizedData } from '../utils/sanitize';
import { Widget } from '../components/Widget';

const Paraaf = ({ data }) => 
  {
    return (
  <Widget className="paraaf">
    <div className="inner">
      <p dangerouslySetInnerHTML={sanitizedData(data.title)} />
    </div>
  </Widget>
  )
;}

export default Paraaf;